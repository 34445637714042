// we only have 6 reaction emojis so widht and height can be static
const EMOJI_DROPDOWN_WIDTH = 230
const EMOJI_DROPDOWN_HEIGHT = 40
import AppendToBody from '../../commons/append_to_body.js'

export default {
	name: 'message-event-action',
	props: ['ev'],

	data() {
		return {
			isEmojiDropdownOpened: false,
		}
	},

	methods: {
		renderEmojiDrodown() {
			if (!this.isEmojiDropdownOpened) return

			let style = this.emojiDropdownStyle || ''
			style += `position: absolute; width: ${EMOJI_DROPDOWN_WIDTH}px; max-width: unset; height: ${EMOJI_DROPDOWN_HEIGHT}px; max-height: unset; overflow: hidden; z-index: 99; padding: 6px 10px; border-radius: 20px`

			return (
				<AppendToBody>
					<div class='dropdown' style={style} vOn:click_stop={() => false}>
						<div class='d-flex'>
							<div class='message_event_action_emoji'>
								<Emoji emojiCode='❤️' lg />
							</div>
							<div class='message_event_action_emoji'>
								<Emoji emojiCode='😆' lg />
							</div>
							<div class='message_event_action_emoji'>
								<Emoji emojiCode='😯' lg />
							</div>
							<div class='message_event_action_emoji'>
								<Emoji emojiCode='😢' lg />
							</div>
							<div class='message_event_action_emoji'>
								<Emoji emojiCode='😡' lg />
							</div>
							<div class='message_event_action_emoji'>
								<Emoji emojiCode='👍️' lg />
							</div>
						</div>
					</div>
				</AppendToBody>
			)
		},

		toggleEmojiDropdown() {
			this.isEmojiDropdownOpened = !this.isEmojiDropdownOpened

			if (this.isEmojiDropdownOpened) this.calculateEmojiDropdownStyle()
		},

		calculateEmojiDropdownStyle() {
			// return 'bottom-left', 'bottom-right', 'top-left', 'top-right'
			let $trigger = this.$refs.trigger.$el
			let rect = $trigger.getBoundingClientRect() || {}

			let {x = 0, y = 0, width = 0, height = 0, bottom = 0, top = 0} = rect

			// choose 10px for edge with screen
			let isTop = window.innerHeight - (y + height) < EMOJI_DROPDOWN_HEIGHT + 10
			let isRight = window.innerWidth - x < EMOJI_DROPDOWN_WIDTH + 10
			let position = 'bottom-left'
			if (isTop && isRight) position = 'top-right'
			if (isTop) position = 'top-left'
			if (isRight) position = 'bottom-right'

			if (position === 'top-right') {
				this.emojiDropdownStyle = `bottom: ${window.innerHeight - y}px; right: ${
					window.innerWidth - right
				}px; left: unset; top: unset;`
				return
			}
			if (position === 'top-left') {
				this.emojiDropdownStyle = `bottom: ${window.innerHeight - y}px; left: ${x}px; right: unset; top: unset;`
				return
			}
			if (position === 'bottom-right') {
				this.emojiDropdownStyle = `top: ${bottom}px; right: ${window.innerWidth - right}px; left: unset; bottom: unset;`
				return
			}
			this.emojiDropdownStyle = `top: ${bottom}px; left: ${x}px; right: unset; bottom: unset;`
		},
	},

	render() {
		let byType = lo.get(this.ev, 'by.type')
		if (byType !== 'user') byType = 'agent'

		return null // because reply API only available in ZaloOA, so this feature doest ready yet
		return (
			<div class={{message_event_actions: true, agent: byType === 'agent', keep: this.isEmojiDropdownOpened}}>
				{/*
				<div
					class={{message_event_action_link: true, agent: byType === 'agent'}}
					v-clickaway={() => (this.isEmojiDropdownOpened = false)}
				>
					<Icon
						name='mood-smile'
						size='18'
						title={this.$t('send_reaction')}
						vOn:click={this.toggleEmojiDropdown}
						ref='trigger'
					/>
					{this.renderEmojiDrodown()}
				</div>
        */}
				<div class={{message_event_action_link: true, agent: byType === 'agent'}}>
					<Icon
						name='corner-up-left'
						size='18'
						title={this.$t('reply')}
						vOn:click={() => this.$emit('action', 'reply')}
					/>
				</div>
			</div>
		)
	},
}
