const sb = require('@sb/util')
import MessageEventActions from './message_event_actions.js'

export default {
	name: 'audio-attachments',
	props: ['ev'],
	data() {
		return {
			focusedIdx: -1,
		}
	},

	created() {
		this.$root.$on('focusEvent', this.justFocus)
	},

	beforeDestroy() {
		this.$root.$off('focusEvent', this.justFocus)
	},

	methods: {

		justFocus(ev) {
			if (ev.id !== this.ev.id) return
			let attachmentIndex = sb.getMsgField(ev, 'attachment_index')
			let attachments = lo.get(ev, 'data.message.attachments') || []
			let att = attachments[attachmentIndex]
			if (!att) return // quote text message
			this.focusedIdx = attachmentIndex
			if (this.focusTimeout) clearTimeout(this.focusTimeout)
			this.focusTimeout = setTimeout(() => {
				this.focusedIdx = -1
			}, 3000)
		},
		onAction(actionType, data, idx) {
			if (actionType === 'reply') return this.onReplyVideo(idx)
		},

		onReplyVideo(idx) {
			let ev = lo.cloneDeep(this.ev)
			lo.set(ev, 'data.message.text', '')
			sb.setMsgField(ev, 'attachment_index', idx)
			this.$emit('reply', ev)
		},
	},
	render() {
		let byType = lo.get(this.ev, 'by.type')
		if (byType !== 'user') byType = 'agent'
		var attachments = lo.get(this.ev, 'data.message.attachments', [])
		var audios = []
		lo.each(attachments, (att, i) => {
			if (!att) return
			if (lo.includes(att.mimetype, 'audio')) audios.push({...att, _true_idx: i})
		})
		if (audios.length === 0) return null

		var $audios = lo.map(audios, (audio, index) => {
			return (
				<div
					class={{
						message_audio: true,
						message_audio__user: byType === 'user',
						message_audio__agent: byType !== 'user',
						focused: audio._true_idx === this.focusedIdx,
					}}
				>
					<audio class='message__audio' controls>
						<source src={sb.absURL(audio.url)} type={audio.mimetype} />
					</audio>
					<MessageEventActions ev={this.ev} vOn:action={(type, data) => this.onAction(type, data, audio._true_idx)} />
				</div>
			)
		})
		return <div class={`message_container message_container__${byType}`}>{$audios}</div>
	},
}
