export const STANDARD_UNLIMITED_VND_PRICE = 3_994_000 //vnd
export const STANDARD_UNLIMITED_MAX_AGENTS = 100
export const STANDARD_UNLIMITED_MINIMUM_CYCLE = 12 // month

// by month
export const CYCLE_DISCOUNT_PERCENTS = {
	// 1: 0,
	3: 0.05,
	6: 0.1,
	12: 0.2,
	24: 0.3,
}

// by month
export const STANDARD_UNLIMITED_DISCOUNT_PERCENTS = {
	12: 0,
	24: 0.1,
}

export function getAvailablePlans() {
	let currentPlan = lo.get(store.matchSubscription(), 'plan') || 'trial'

	if (currentPlan === 'trial' || currentPlan === 'custom')
		return ['standard_unlimited_agent', 'standard', 'advanced', 'custom']
	if (currentPlan === 'standard_unlimited_agent') {
		return ['standard_unlimited_agent', 'custom']
	}
	if (currentPlan === 'advanced') return ['standard', 'advanced', 'custom']
	if (currentPlan === 'standard') {
		let numAgents = lo.get(store.matchSubscription(), 'num_agents') || 1
		if (numAgents <= 1) {
			return ['standard_unlimited_agent', 'standard', 'advanced', 'custom']
		}
		return ['standard', 'advanced', 'custom']
	}
}
