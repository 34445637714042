import IconWeb from './icon.web.js'
import IconMobile from './icon.mobile.js'

let Icon
if (process.env.IS_WEB) {
	Icon = IconWeb
} else {
	Icon = IconMobile
}

export default Icon
