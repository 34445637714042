var sb = require('@sb/util')
import MessageEventActions from './message_event_actions.js'

export default {
	name: 'video-attachments',
	props: ['ev'],
	data() {
		return {
			focusedIdx: -1,
		}
	},

	created() {
		this.$root.$on('focusEvent', this.justFocus)
	},

	beforeDestroy() {
		this.$root.$off('focusEvent', this.justFocus)
	},

	methods: {
		onAction(actionType, data, idx) {
			if (actionType === 'reply') return this.onReplyVideo(idx)
		},

		onReplyVideo(idx) {
			let ev = lo.cloneDeep(this.ev)
			lo.set(ev, 'data.message.text', '')
			sb.setMsgField(ev, 'attachment_index', idx)
			this.$emit('reply', ev)
		},

		justFocus(ev) {
			if (ev.id !== this.ev.id) return
			let attachmentIndex = sb.getMsgField(ev, 'attachment_index')
			let attachments = lo.get(ev, 'data.message.attachments') || []
			let att = attachments[attachmentIndex]
			if (!att) return // quote text message
			this.focusedIdx = attachmentIndex
			if (this.focusTimeout) clearTimeout(this.focusTimeout)
			this.focusTimeout = setTimeout(() => {
				this.focusedIdx = -1
			}, 3000)
		},
	},
	render() {
		let byType = lo.get(this.ev, 'by.type')
		if (byType !== 'user') byType = 'agent'
		var attachments = lo.get(this.ev, 'data.message.attachments', [])
		var videos = []
		lo.each(attachments, (att, i) => {
			if (!att) return
			if (lo.includes(att.mimetype, 'video')) videos.push({...att, _true_idx: i})
		})

		if (videos.length === 0) return null

		var $videos = lo.map(videos, (video, index) => {
			return (
				<div
					class={{
						message_video: true,
						message_video__user: byType === 'user',
						message_video__agent: byType !== 'user',
						focused: video._true_idx === this.focusedIdx,
					}}
				>
					<video
						class='message_video__video'
						src={sb.replaceFileUrl(video.url)}
						width='100%'
						height=''
						poster={sb.replaceFileUrl(video.thumbnail_url)}
						controls='controls'
						preload='metadata'
						onclick='this.play()'
					></video>
					<MessageEventActions ev={this.ev} vOn:action={(type, data) => this.onAction(type, data, video._true_idx)} />
				</div>
			)
		})
		return <div class={`message_container message_container__${byType}`}>{$videos}</div>
	},
}
