import store from '@sb/store'
const sb = require('@sb/util')
import UserDisplayName from '../commons/user_display_name.js'
import {isTicketUnread, isTicketImportantUnread} from '../ticket/com.js'
import MakePaymentModal from './make_payment_modal.js'

const TABS_SIZE = 3
const LIMIT = 100

export default {
	name: 'activity-tabs',
	props: ['uid', 'cid'],
	data() {
		return {
			displayTabs: [],
		}
	},

	created() {
		this.convoTabs = []
	},

	mounted() {
		store.onRelatedConvos(this, (_) => this.$forceUpdate())
		store.onTopic(this, (cid) => {
			let relatedConvos = store.getRelatedConvos(this.cid)
			if (lo.find(relatedConvos, (convo) => convo.id === cid)) {
				this.$forceUpdate()
				this.setConvoTabs()
			}
		})
		store.onConvo(this, (convoM) => {
			if (convoM[this.cid]) {
				this.$forceUpdate()
				return
			}
			let relatedConvos = store.getRelatedConvos(this.cid)
			if (lo.find(relatedConvos, (convo) => convoM[convo.id])) {
				this.$forceUpdate()
			}
		})
		this.setConvoTabs()
		this.onSelectTab()
	},

	watch: {
		uid() {
			this.setConvoTabs()
			this.onSelectTab()
		},

		cid() {
			this.onSelectTab()
		},
	},

	methods: {
		closeMakePaymentModal() {
			this.showMakePaymentModal = false
		},

		onSelectTab() {
			let cid = this.cid
			let currentConvoTab = lo.find(this.convoTabs, (tab) => lo.includes(tab.convoids, cid))

			if (!currentConvoTab) {
				this.setConvoTabs()
				return
			}

			if (lo.find(this.displayTabs, (tab) => lo.includes(tab.convoids, cid))) return
			let selecttab = currentConvoTab
			let displayTabs = lo.cloneDeep(this.displayTabs)
			if (lo.size(displayTabs) === TABS_SIZE) {
				displayTabs[TABS_SIZE - 1] = selecttab
			} else {
				displayTabs.push(selecttab)
			}
			this.displayTabs = displayTabs
		},

		openMakePaymentModal() {
			this.showMakePaymentModal = true
		},

		setConvoTabs() {
			let convo = store.matchConvo(this.cid) || {id: this.cid}
			let channel = lo.get(convo, 'touchpoint.channel')
			let relatedConvos = store.getRelatedConvos(this.cid)
			if (!lo.size(relatedConvos)) {
				let name = ''
				if (channel === 'call') name = this.$t('call')
				else if (channel === 'facebook') name = this.$t('Messenger')
				else if (channel === 'facebook_comment') name = this.$t('comment')
				else if (channel === 'instagram') name = this.$t('Instagram')
				else if (channel === 'instagram_comment') name = this.$t('Insta Comment')
				else if (!sb.isTopicIdConvo(this.cid))
					name = this.$t('ticket_short') + ' ' + lo.get(store.matchTicket(this.cid), 'number', '')
				else name = this.$t('conversation')
				this.convoTabs = [
					{
						name,
						convoids: [this.cid],
						tab_id: this.cid,
					},
				]
				this.displayTabs = lo.cloneDeep(this.convoTabs)
				return
			}

			let allConvos = [convo, ...relatedConvos]
			let messengers = []
			let comments = []

			lo.each(allConvos, (convo) => {
				if (!sb.isTopicIdConvo(convo.id)) {
					return
				}

				let name = ''
				let channel = lo.get(convo, 'touchpoint.channel')
				if (channel === 'call') name = this.$t('call')
				else if (channel === 'facebook') name = this.$t('Messenger')
				else if (channel === 'facebook_comment') name = this.$t('comment')
				else if (channel === 'instagram') name = this.$t('Instagram')
				else if (channel === 'instagram_comment') name = this.$t('Insta Comment')
				else name = this.$t('conversation')
				if (channel === 'facebook_comment' || channel === 'instagram_comment') {
					comments.push({
						name,
						convoids: [convo.id],
						tab_id: convo.id,
						_created: lo.get(store.matchConvo(convo.id), 'created', 0),
					})
					return
				}

				if (lo.size(messengers)) {
					messengers[0].convoids.push(convo.id)
				} else {
					messengers.push({
						name,
						convoids: [convo.id],
						tab: convo.id,
					})
				}
			})

			comments = lo.orderBy(comments, ['_created'], ['asc'])
			if (lo.size(comments) > 1) {
				lo.each(comments, (tab, idx) => {
					tab.name = tab.name + ' #' + (idx + 1)
				})
			}
			this.convoTabs = [...messengers, ...comments]
			this.displayTabs = lo.take(this.convoTabs, TABS_SIZE)
		},

		onUcidChange(uid, cid) {
			this.$emit('ucidChange', uid, cid)
		},

		renderMoreTabs() {
			if (lo.size(this.convoTabs) <= TABS_SIZE) return
			let remainTabs = lo.filter(
				this.convoTabs,
				(tab) => !lo.find(this.displayTabs, (currentTab) => currentTab.tab_id === tab.tab_id),
			)

			let items = lo.map(remainTabs, (tab) => {
				let is_read = store.isConvoRead(lo.first(tab.convoids))
				return {
					id: tab.tab_id,
					html: (
						<div class='d-flex align-items-center'>
							<div>{tab.name}</div>
							{!is_read && <div class='dot dot__md dot__danger no-shrink ml-3' />}
						</div>
					),
				}
			})

			return (
				<div key='more_tab' class={{tab__item: true}} style='padding-bottom: 0; margin-left: -10px'>
					<Dropdown
						mode='custom'
						items={items}
						dropdown_width={200}
						vOn:select={(item) => this.onUcidChange(this.uid, item.id)}
					>
						<div style='height: 30px'>
							<Icon name='chevron-down' size='18' />
						</div>
					</Dropdown>
				</div>
			)
		},
	},

		render() {
				return null
		let relatedConvos = store.getRelatedConvos(this.cid)
		let isTicketOnly = !lo.size(relatedConvos) && !sb.isTopicIdConvo(this.cid)
		let user = store.matchUser(this.uid) || {id: this.uid}
		let tabs = this.displayTabs

		return (
			<div class='activity_convo_user_container_header'>
				<div class='activity_convo_user_container_header_info'>
					<div class='activity_convo_user_container_header_user_and_tab'>
						<div class='tab'>
							<div class={{tab__item: true}}>
								<div class='d-flex align-items-center' style='overflow: hidden'>
									<div class='text__truncate'>{this.$t('activities')}</div>
								</div>
							</div>
							{lo.map(tabs, (tab) => {
								let is_read = store.isConvoRead(lo.first(tab.convoids))
								if (!sb.isTopicIdConvo(lo.first(tab.convoids))) {
									let id = lo.first(tab.convoids)
									is_read = !isTicketImportantUnread(store.matchTicket(id))
								}

								let dotStyle = {
									marginTop: 0,
									marginLeft: '5px',
								}
								if (is_read) {
									dotStyle.backgroundColor = 'transparent'
								}
								return (
									<div
										key={lo.first(tab.convoids)}
										class={{tab__item: true, tab__item__active: lo.includes(tab.convoids, this.cid)}}
										vOn:click={() => this.onUcidChange(this.uid, lo.first(tab.convoids))}
									>
										<div class='d-flex align-items-center' style='overflow: hidden'>
											<div class='text__truncate' title={tab.name}>
												{tab.name}
											</div>
											<div class='dot dot__md dot__danger no-shrink' style={dotStyle} />
										</div>
									</div>
								)
							})}
							{this.renderMoreTabs()}
						</div>
					</div>
				</div>
				<div class='activity_convo_user_container_header_actions'>

					{/*
					<div class='btn btn__sm btn__primary ml-3'>{this.$t('send_zns')}</div>
					 */}

					<div class='btn btn__sm btn__outline_success ml-3' vOn:click={this.openMakePaymentModal}>
						{this.$t('make_payment')}
					</div>
				</div>


			</div>
		)
	},
}
