const sb = require('@sb/util')
const flow = require('@subiz/flow')
var api = require('./api.js')
const config = require('@sb/config')

import KV from './kv.js'
import NewObjectStore from './object_store.js'
import NewRealtimeChain from './realtime_chain.js'
import common from './common.js'
import Segment from '@sb/util/segment.js'
import {startOfDay} from 'date-fns'

const TYPING_TIMEOUT = 5000
import InMemKV from './inmem_kv.js'
import DOMPurify from 'dompurify'

// parent {api, realtime, insync, pubsub}
function NewConvoStore(realtime, pubsub, ticketstore) {
	let kv = new InMemKV()
	kv.init()

	let recent_call_db = {}

	let me = {}
	let {agent_id} = api.getCred()
	let account_id = api.getAccountId()

	let ls = new KV(config.db_prefix + account_id + '.convo')
	ls.init()

	realtime.subscribe([
		`conversation_events.account.${account_id}.agent.${agent_id}`,
		'message_pinned.account.' + account_id + '.agent.' + agent_id,
		'recent_call_updated.account.' + account_id + '.agent.' + agent_id,
		'message_unpinned.account.' + account_id + '.agent.' + agent_id,
		'inbox_updated.' + account_id + '.agent.' + agent_id,
		'my_inbox_updated.account.' + account_id + '.agent.' + agent_id,
	])

	let msgchain = NewRealtimeChain(account_id + '.message', realtime, pubsub, [], true)

	let convo_list_dirty = true // user list

	let convo_list = {} // original
	let convo_list_by_user = {} // uid => list
	let grouped_convo_list = []
	let filtered_convo_list = []
	let convo_list_view_filter = ls.match('convo_filter') || {}

	let convo_sync_state = false
	realtime.onInterrupted(() => {
		convo_sync_state = {} // 'offline'
	})

	let db = NewObjectStore(
		account_id,
		realtime,
		pubsub,
		'convo',
		async (convos) => {
			let ids = lo.map(convos, 'id')
			let last_modifieds = lo.map(convos, (convo) => lo.get(convo, 'updated', 0))
			var {body, code, error} = await api.matchConversations({ids, last_modifieds})
			if (error || code !== 200) return {error}
			let conversations = lo.get(body, 'conversations', [])
			return {data: conversations}
		},
		[], // no topics
		true,
	)
	db.onchange = (convoM) => {
		lo.map(convoM, (convo, convoid) => {
			lo.map(convo.members, (mem) => {
				if (mem.type !== 'user') return
				let userid = mem.id
				let convos = kv.match('convos:' + userid) || []
				if (convos.indexOf(convoid) != -1) return
				convos.push(convoid)
				kv.put('convos:' + userid, convos)
			})
		})
	}

	// use for convo messages memcache
	me.getConvoCacheMessage = (cid) => convoOfTouchpointCache[cid]
	me.setConvoCacheMessage = (cid, msg) => {
		convoOfTouchpointCache[cid] = msg
	}

	me.count_touchpoint = api.count_touchpoint

	let _lastSeeing = 0
	// Send presence update every 20 seconds
	me.loopSendPresence = () => {
		if (api.getAccountId()) {
			api.pingPresence({is_focused: document.hasFocus()})
		}
		flow.loop(async () => {
			await flow.sleep(20000)
			if (!api.getAccountId()) return
			if (Date.now() - _lastSeeing < 10000) return true // keep looping
			let me = store.me()
			if (!me || !document.hasFocus) return true
			await api.pingPresence({is_focused: document.hasFocus()})
			return true
		})
	}
	me.loopSendPresence()

	me.countTopic = (filter) =>
		lo.filter(convo_list, (conv) => checkConvoFilter(filter, conv) && isConvoCountInTime(conv)).length

	me.matchConvoListFilter = () => convo_list_view_filter
	me.updateConvoListFilter = (filter) => {
		convo_list_view_filter = lo.cloneDeep(filter)
		ls.put('convo_filter', convo_list_view_filter)
		convo_list_dirty = true
	}

	me.updateConvoList = (conv) => {
		let current = convo_list[conv.id]

		// keep _primary_id and _related
		if (current && typeof current === 'object') {
			conv._primary_id = current.primary_id
			conv._related = current._related
		}

		convo_list[conv.id] = conv
		me.rebuildGroupedTopics()
		convo_list_dirty = true
	}

	me.removeConvoList = (id) => {
		delete convo_list[id]
		convo_list_dirty = true
	}

	me.subConvoRealtime = async (ids) => {
		let topics = []
		lo.map(ids, (id) => {
			if (!id) return
			topics.push('conversation_events.account.' + account_id + '.conversation.' + id)
		})
		return realtime.subscribe(topics)
	}

	let offlineActionKV = {}

	let typing_at_map = {}
	let handleRT = async (ev) => {
		if (!ev || !ev.type) return
		delete ev.account_id
		ev.created = sb.getMs(ev.created)
		let dbconvo
		switch (ev.type) {
			case 'user_info_updated':
				var newuser = lo.get(ev, 'data.user', {})
				if (!newuser.id) return
				if (!udb.has(newuser.id)) return
				var user = udb.match(newuser.id) // remember to merge with old fields
				user = Object.assign(user, newuser)
				if (lo.size(newuser.labels) == 0) delete user.labels
				return udb.put(user.id, user)

			case 'recent_call_updated':
				let call = lo.get(ev, 'data.recent_call_record', {})
				if (!call.conversation_id) return
				recent_call_db[call.conversation_id] = call
				me.onChangeRecentCall()
				return

			case ('inbox_updated', 'my_inbox_updated'):
				var conv = lo.get(ev, 'data.conversation_meta') || {id: ''}
				if (conv.actived == -1) {
					me.removeConvoList(conv.id)
					pubsub.publish('topic', conv.id)
					return
				}
				// make sure new topic has user to render
				if (sb.isTopicIdConvo(conv.id)) {
					dbconvo = me.matchConvo(conv.id)
					if (!dbconvo) await store.fetchConvos([conv.id], true)
					if (conv.user_id) me.fetchUser(conv.user_id) // make sure user loaded
				} else if (lo.startsWith(conv.id, 'us')) {
					me.fetchUser(conv.id) // make sure user loaded
				} else if (lo.startsWith(conv.id, 'tk')) {
					let dbticket = ticketstore.matchTicket(conv.id)
					if (!dbticket) await ticketstore.fetchTickets([conv.id], true)
					// dont need to publish ticket topic
					return
				}
				me.updateConvoList(conv)
				pubsub.publish('topic', conv.id)
				return
			case 'conversation_updated':
				var convo = lo.get(ev, 'data.conversation', {})
				if (!convo.id) return
				db.put(convo.id, convo)
				pubsub.publish('convo', {[convo.id]: convo})
				return
			case 'user_event_created':
				var user_event = lo.get(ev, 'data.event', {})
				if (!user_event.id) return

				if (user_event.type == 'presence_updated') {
					var userid = lo.get(user_event, 'data.presence.user_id')
					if (!userid) userid = lo.get(user_event, 'by.id')
					if (!udb.has(userid)) return
					var user = udb.match(userid)
					sb.setUserAttr(user, {key: 'seen', datetime: new Date(ev.created).toISOString()})
					sb.setUserAttr(user, {key: 'focused', boolean: lo.get(user_event, 'data.presence.is_focused', false)})
					return udb.put(user.id, user)
				}
				if (user_event.type == 'content_viewed') {
					if (!userid) userid = lo.get(user_event, 'by.id')
					if (!udb.has(userid)) return
					var user = udb.match(userid)
					sb.setUserAttr(user, {key: 'seen', datetime: new Date(ev.created).toISOString()})
					sb.setUserAttr(user, {key: 'focused', boolean: true})
					udb.put(user.id, user)
				}

				var events = kv.match('events:' + user_event.user_id) || []
				events = [...events, user_event]

				if (ev.type == 'user_event_created') {
					if (lo.get(ev, 'data.event.type') == 'content_viewed') {
						if (udb.has(ev.user_id)) {
							var user = udb.match(ev.user_id) // remember to merge with old fields
							user = Object.assign(user, {latest_content_view: ev.data.event})
							return udb.put(ev.user_id, user)
						}
					}
				}

				kv.put('events:' + user_event.user_id, events)
				pubsub.publish('user_event', user_event.user_id, ev.type, ev)
				return
			case 'event_created':
				var user_event = lo.get(ev, 'data.event', {})
				if (!user_event.id) return
				var events = kv.match('events:' + user_event.user_id) || []
				events = [...events, user_event]
				kv.put('events:' + user_event.user_id, events)
				pubsub.publish('user_event', user_event.user_id, ev.type, ev)
				return
		}

		var convoid = sb.convoOfEv(ev)

		if (!convoid) return
		let oldConvo = db.match(convoid)
		switch (ev.type) {
			case 'conversation_rated':
				var ratings = lo.get(ev, 'data.conversation.ratings')
				if (lo.size(ratings) === 0) return
				convo = Object.assign({}, oldConvo)
				if (!convo.id) return
				convo.ratings = convo.ratings || []
				convo.ratings = convo.ratings.concat(ratings)
				db.put(convo.id, convo)
				pubsub.publish('convo', {[convo.id]: convo})
				break

			case 'message_pinned':
				var msgid = lo.get(ev, 'data.event.id')
				var oldev = msgchain.match(convoid, msgid, listmessageapi, 'message')
				if (!oldev) return
				oldev.data.message.pinned = Date.now()
				oldev.data.message.pinned_by = ev.by.id
				oldev.updated = Date.now()
				await msgchain.put(convoid, oldev.id, sb.getMs(oldev.created), oldev)
				await msgchain.put(convoid + '&pinned', oldev.id, sb.getMs(oldev.created), oldev)

				break
			case 'message_unpinned':
				var msgid = lo.get(ev, 'data.event.id')
				var oldev = msgchain.match(convoid, msgid, listmessageapi, 'message')
				if (!oldev) return
				oldev.data.message.pinned = 0
				oldev.data.message.pinned_by = ev.by.id
				oldev.updated = Date.now()
				await msgchain.put(convoid, oldev.id, sb.getMs(oldev.created), oldev)
				await msgchain.del(convoid + '&pinned', oldev.id)
			case 'message_sent':
				if (!oldConvo) return
				var oldev = msgchain.match(convoid, ev.id, listmessageapi, 'message')
				if (oldev) return

				oldConvo.last_message = lo.get(ev, 'data.message', {})
				oldConvo.last_message_sent = ev
				oldConvo.updated = ev.created
				oldConvo.last_message_id = ev.id

				lo.map(oldConvo.members, (mem) => {
					if (mem.id === lo.get(ev, 'by.id')) {
						mem.last_sent = ev.created
					}
				})

				// mention
				if (lo.get(ev, 'data.message.format') == 'delta') {
					let delta = sb.parseJSON(ev.data.message.text)
					let ops = lo.get(delta, 'ops') || []
					lo.map(ops, (op) => {
						let mentionid = lo.get(op, 'insert.mention.id')
						if (!mentionid) return
						if (mentionid == 'all' || mentionid == '*') {
							lo.map(oldConvo.members, (mem) => {
								if (mem.type == 'user') return
								if (mem.id == ev.by.id) return // dont self-mention
								mem.last_mentioned = ev.created
							})
						} else {
							lo.map(oldConvo.members, (mem) => {
								if (mem.id == mentionid) return
								if (mem.id == ev.by.id) return // dont self-mention
								mem.last_mentioned = ev.created
							})
						}
					})
				}

				db.put(convoid, oldConvo)
				break
			case 'message_updated':
				console.log('handleRT message_updated')
				if (!oldConvo) return
				pubsub.publish('message_updated', ev)
				break
			case 'message_pong':
				// check viewing
				lo.map(lo.get(ev, 'data.message.pongs', []), (pong) => {
					if (pong.type == 'seen') {
						// if seen this convo => disable typing on other convo
						lo.map(typing_at_map, (v, k) => {
							if (!v) return
							if (k.endsWith('-' + pong.member_id) && !k.startsWith(convoid)) {
								typing_at_map[k] = 0
								pubsub.publish('typing', k.split('-')[0])
							}
						})
					}
				})

				// update pongged message
				var msgid = lo.get(ev, 'data.message.id')
				var oldev = msgchain.match(convoid, msgid, listmessageapi, 'message')
				if (lo.get(oldev, 'data.message')) {
					var pongs = lo.get(oldev, 'data.message.pongs', [])
					pongs = pongs.concat(lo.get(ev, 'data.message.pongs', []))

					pongs = lo.filter(pongs, (pong1) => {
						if (pong1.type.startsWith('remove_')) return false
						let cancel = lo.find(
							pongs,
							(pong2) => pong1.member_id == pong2.member_id && pong2.type === 'remove_' + pong1.type,
						)
						return !cancel
					})

					let goodpongs = []
					lo.map(pongs, (pong) => {
						let i = lo.findIndex(goodpongs, (gp) => gp.member_id == pong.member_id && gp.type == pong.type)
						let gp = goodpongs[i]
						if (!gp) {
							// add new pong
							goodpongs.push(pong)
							return
						}
						// replace old pong
						if (gp.created < pong.created) goodpongs[i] = pong
					})
					pongs = goodpongs

					// cancel out remove
					oldev.data.message.pongs = pongs
					oldev = lo.cloneDeep(oldev)
					oldev.updated = Date.now()
					await msgchain.put(convoid, oldev.id, sb.getMs(oldev.created), oldev)
				}
				convo = Object.assign({}, oldConvo)
				if (!convo.id) break
				let changeconvo = false
				pongs = lo.get(ev, 'data.message.pongs', [])
				lo.map(pongs, (pong) => {
					lo.map(convo.members, (mem) => {
						if (mem.id === pong.member_id) {
							if (pong.type === 'seen' || pong.type == 'receive') changeconvo = true
							if (pong.type === 'seen') mem.seen_at = sb.getMs(pong.created)
							if (pong.type === 'receive') mem.received_at = sb.getMs(pong.created)
						}
					})
				})
				if (changeconvo) db.put(convo.id, convo)
				break
			case 'conversation_state_updated':
			case 'conversation_joined':
			case 'conversation_left':
			case 'conversation_tagged':
			case 'conversation_untagged':
				db.fetch([sb.convoOfEv(ev)], true)
				break
			case 'conversation_rating_requested':
			case 'conversation_typing':
				typing_at_map[`${convoid}-${ev.by.id}`] = ev.created
				pubsub.publish('typing', convoid)
				setTimeout(() => pubsub.publish('typing', convoid), TYPING_TIMEOUT + 1000) // try to let client know to clear typing
			case 'message_updated':
			default:
		}

		// dont store pong and typing
		if (ev.type !== 'message_pong' && ev.type !== 'conversation_typing') {
			await msgchain.put(convoid, ev.id, sb.getMs(ev.created), ev)
		}
		if (ev.type !== 'conversation_typing') {
			pubsub.publish('message', convoid, ev.type, ev)
		}
	}
	realtime.onEvent(handleRT)

	me.getTypingAt = (convoid, memberid) => {
		return typing_at_map[`${convoid}-${memberid}`] || 0
	}

	me.isMemberTyping = (cid, member, test) => {
		let typingAt = me.getTypingAt(cid, member.id)
		if (typingAt + TYPING_TIMEOUT < Date.now()) return false
		if (member.last_sent >= typingAt) return false
		return true
	}

	me.upload = common.upload
	me.uploadLocalFile = common.uploadLocalFile

	me.search = (col, keyword, anchor, limit, parts, distinct) => api.search(col, keyword, anchor, limit, parts, distinct)

	me.tagConvo = async (cid, tid) => {
		let {error} = await api.tagConvo(cid, tid)
		if (error) return {error}
		return me._refreshConvo(cid)
	}

	me.untagConvo = async (cid, tid) => {
		var {error} = await api.untagConvo(cid, tid)
		if (error) return {error}
		return me._refreshConvo(cid)
	}

	me._refreshConvo = async (cid) => {
		let {body: convo, error} = await api.getConversation(cid)
		if (error) return {error}
		db.put(cid, convo)
		return {convo}
	}

	me.assignAgent = async (cid, member) => {
		var {error} = await api.assignAgent(cid, member)
		if (error) return {error}
		return me._refreshConvo(cid)
	}

	me.unassignAgent = async (cid, member) => {
		var {error} = await api.unassignAgent(cid, member)
		if (error) return {error}
		return me._refreshConvo(cid)
	}

	me.updateMember = async (mem) => {
		let {error} = await api.updateMember(mem)
		if (error) return {error}
		return me._refreshConvo(mem.conversation_id)
	}

	me.updateConvo = async (conver) => {
		let {error} = await api.updateConvo(conver)
		if (error) return {error}
		return me._refreshConvo(conver.id)
	}

	me.muteConvo = async (cid) => {
		let {error} = await api.muteConversation(cid)
		if (error) return {error}
		return me._refreshConvo(cid)
	}

	me.markAsSpam = async (cid) => {
		let {error} = await api.mark_convo_spam(cid)
		if (error) return {error}
		setTimeout(() => pubsub.publish('topic', cid), 500) // so the user can notice the change
		return me._refreshConvo(cid)
	}

	me.unmarkSpam = async (cid) => {
		let {error} = await api.unmark_convo_spam(cid)
		if (error) return {error}
		setTimeout(() => pubsub.publish('topic', cid), 500) // so the user can notice the change
		return me._refreshConvo(cid)
	}

	me.unwatchConvo = async (cid) => {
		let {error} = await api.unwatchConversation(cid)
		if (error) return {error}
		setTimeout(() => pubsub.publish('topic', cid), 500) // so the user can notice the change
		return me._refreshConvo(cid)
	}

	me.dismissConvo = async (cid) => {
		let {error} = await api.dismissConversation(cid)
		if (error) return {error}
		setTimeout(() => pubsub.publish('topic', cid), 500) // so the user can notice the change
		return me._refreshConvo(cid)
	}

	me.rewatchConvo = async (cid) => {
		let {error} = await api.rewatchConversation(cid)
		if (error) return {error}
		setTimeout(() => pubsub.publish('topic', cid), 500) // so the user can notice the change
		return me._refreshConvo(cid)
	}

	me.unmuteConvo = async (cid) => {
		let {error} = await api.unmuteConversation(cid)
		if (error) return {error}
		return me._refreshConvo(cid)
	}

	me.endConvo = async (cid) => {
		let {error} = await api.endConvo(cid)
		if (error) return {error}
		return me._refreshConvo(cid)
	}

	me.startConvo = async (convo) => {
		let {body: out, error} = await api.startConvo(convo)
		if (error) return {error}
		if (!out || !out.id) return {}
		db.put(out.id, out)
		return {convo: out}
	}

	me.isConvoRead = (id) => {
		if (!id) return true
		let convo = store.matchConvo(id)
		if (lo.get(convo, 'touchpoint.channel') === 'call') return true
		if (lo.get(convo, 'last_message_sent.created', 0) < 100) return true
		if (lo.get(convo, 'last_message_sent.by.id') === agent_id) return true
		let foundmember = false
		let maxseen = 0
		let ismuted = false
		let me = lo.map(convo.members, (m) => {
			if (m.id == agent_id) {
				ismuted = m.is_muted
				foundmember = true
				if (sb.getMs(m.seen_at) > maxseen) maxseen = sb.getMs(m.seen_at)
				if (sb.getMs(m.last_sent) > maxseen) maxseen = sb.getMs(m.last_sent)
			}
		})
		if (!foundmember) return true
		if (ismuted) return true
		return sb.getMs(lo.get(convo, 'last_message_sent.created')) < sb.getMs(maxseen)
	}

	me.matchConvo = (id) => {
		if (!id || !id.startsWith('cs')) return
		if (id.endsWith('&pinned')) id = id.substr(id, id.length - 7)
		let convo = db.match(id)

		return convo
	}

	me.fetchConvos = (ids, f) => db.fetch(ids, f)

	me.fetchMoreMessages = (convoid) => msgchain.fetchMore(convoid, 50, listmessageapi)
	me.listMessages = (convoid) => {
		let msgM = msgchain.list(convoid, listmessageapi, 'message')
		// filter out already sent
		// filter out sending but already received realtime ev

		lo.map(offlineActionKV, (offlineEv) => {
			// delete too old msg
			if (sb.now() - offlineEv.created > 86400000) {
				delete offlineActionKV[offlineEv.id] // ignore
				return
			}
			if (sb.convoOfEv(offlineEv) !== convoid) return

			let found = lo.find(msgM, (ev) => {
				let idemkey = lo.get(ev, 'data.message.idempotency_key')
				// message send success
				return offlineEv.id === idemkey
			})

			if (found) {
				delete offlineActionKV[offlineEv.id] // ignore
				found.created = offlineEv.created
				return
			}

			// item haven't been sent
			msgM[offlineEv.id] = offlineEv
		})

		return msgM
	}

	me.resetEventAnchor = (convoid) => msgchain.resetAnchor(convoid)
	me.fetchMoreMessages2 = async (userid, convoid) => {
		let [{end}] = await Promise.all([
			msgchain.fetchMore(convoid, 50, listmessageapi),
			me.fetchUserEvents2(userid, 0, Math.floor(Date.now() / 3600000) - 72),
		])
		return {end}
	}

	me.listMessages2 = (userid, convoid) => {
		let messageM = me.listMessages(convoid)
		let convomessage = lo.values(messageM)
		if (lo.size(convomessage) !== 0) {
			let start_hour = Date.now() - 72 * 3600_000

			let userevent = me.matchUserEvents(userid)
			userevent = lo.filter(userevent, (event) => {
				if (event.type == 'channel_removed') return
				if (event.type == 'channel_integrated') return
				if (event.type == 'content_viewed') return Date.now() - 6 * 3600_000 <= event.created
				return start_hour <= event.created
			})

			for (let i = 0; i < userevent.length; i++) {
				if (messageM[userevent[i].id]) continue
				convomessage.push(userevent[i])
			}
		}
		return lo.keyBy(convomessage, 'id')
	}

	me.matchMessage2 = (userid, convoid, eid) => {
		let message = msgchain.match(convoid, eid)
		if (message) return message

		let offlineEv = offlineActionKV[eid]
		if (offlineEv) return offlineEv

		if (userid == '') return
		let userevent = me.matchUserEvents(userid)
		if (!userevent || !userevent.length) return
		for (let i = 0; i < userevent.length; i++) {
			if (eid === userevent[i].id) {
				return userevent[i]
			}
		}
		//return lo.find(events, (event) => event.id == eid)
	}

	let sendQ = new flow.batch(1, 1, async (evs) => {
		let ev = evs[0]
		let convoid = sb.convoOfEv(ev)

		if (ev._error) return
		if (!ev._sending) return

		// upload file first
		let atts = lo.get(ev, 'data.message.attachments', [])
		for (var i = 0; i < atts.length; i++) {
			if (lo.get(atts, [i, 'type']) !== 'file') continue
			if (!atts[i]._file) continue
			let {url, error} = await common.upload(atts[i])
			if (error) {
				// keep error op so user can see it, we will remove it after a day
				ev._error = error
				offlineActionKV[ev.id] = ev
				return pubsub.publish('message', convoid, 'message_sent', ev)
			}
			atts[i].url = url
		}

		// wait for realtime
		let apiev = Object.assign({}, ev)
		delete apiev._sending
		delete apiev.id
		let {body, code, error} = await api.sendMsgEvent(apiev)
		// offlineActionKV.del(ev.created + '')
		if (error) {
			if (code === 0) ev._error = 'network_error'
			else ev._error = error

			offlineActionKV[ev.id] = ev
			pubsub.publish('message', convoid, 'message_sent', ev)
			return [{error}]
		}

		ev._sending = false
		// dont update chain because it may cause corruption
		// (see chain guarantees), shoud only update through realtime
		// or list from api
		if (body && body.id) handleRT(body)

		pubsub.publish('message', convoid, 'message_sent', ev)
		return [{body, code, error}]
	})

	me.sendConvoEv = async (cid, ev) => {
		let {body: event, error} = await api.sendConvoEvent(cid, ev)
		return {event, error}
	}

	me.matchUserConvos = (userid) => {
		let convos = kv.match('convos:' + userid)
		return lo.map(convos, (id) => me.matchConvo(id))
	}

	me.fetchUserConvos = async (userid) => {
		let {body, error} = await api.listUserConversations(userid, '', -500)
		if (error) return {error}
		let conversations = lo.get(body, 'conversations')
		lo.map(conversations, (convo) => db.put(convo.id, convo))
		kv.put('convos:' + userid, lo.map(conversations, 'id'))
		return {conversations}
	}

	me.listUserRelatedProfiles = api.listUserRelatedProfiles
	me.deleteUserRelatedProfile = api.deleteUserRelatedProfile

	let convoOfTouchpointCache = {}
	me.listConvosOfTouchpoint = async (channel, source, id) => {
		let oldcreated = lo.get(convoOfTouchpointCache, [channel + '/' + source + '/' + id, 'created'], 0)
		if (Date.now() - oldcreated < 30000) return convoOfTouchpointCache[channel + '/' + source + '/' + id].ids

		let {body, error} = await api.listConvosOfTouchpoint(channel, source, id)
		if (error) return []
		if (!body || !body.conversations) return []

		let ids = lo.map(body.conversations, 'id')
		ids.sort()
		ids.reverse()
		convoOfTouchpointCache[channel + '/' + source + '/' + id] = {ids, created: Date.now()}
		return ids
	}
	me.getFacebookLinkRef = api.getFacebookLinkRef
	me.pongMessage = (convoid, mid, pong) => api.pongMessage(convoid, mid, pong)
	me.sendMessage2 = async ({touchpoint, msg, convoid, userids, comment_id}) => {
		if (!msg) return
		let {message, error} = await replaceMessageQuillDeltaBase64Images(msg)
		if (error) return {error}
		msg = message
		let ev = prepareMessageEvent2(convoid, touchpoint, msg, api.getCred().agent_id, userids, comment_id)
		if (!ev) return
		offlineActionKV[ev.id] = ev

		if (convoid) {
			// insert convoid to offline event
			lo.set(offlineActionKV, `${ev.id}.data.message.conversation_id`, convoid)
			pubsub.publish('message', convoid, 'message_sent', ev)
		}
		return sendQ.push(ev) // send later
	}

	async function replaceMessageQuillDeltaBase64Images(message) {
		message = lo.cloneDeep(message)
		let delta = sb.parseJSON(message.quill_delta)
		let blobs = getAllMessageQuillDeltaBlobPaths(message)

		let error = ''
		if (message.format === 'html') {
			let htmlString = message.text || ''
			htmlString = DOMPurify.sanitize(htmlString, {ALLOW_UNKNOWN_PROTOCOLS: true})

			const parser = new DOMParser()
			const dom = parser.parseFromString(htmlString, 'text/html')
			const $imgs = dom.querySelectorAll('img')
			const $ps = dom.querySelectorAll('p') // use dir to detect this is p of lexical editor
			for (let i = 0; i < lo.size($imgs); i++) {
				let $img = $imgs[i]
				let url = $img.getAttribute('src')
				if (!isBlobUrl(url) && !isDataUrl(url)) continue
				let file
				if (isBlobUrl(url)) file = await sb.blobUrlToFile(url)
				if (isDataUrl(url)) file = await sb.dataURLToFile(url)
				let res = await common.uploadLocalFile(file)
				if (res.error) {
					error = res.error
				}

				$img.setAttribute('src', res.url)
				$img.setAttribute('subizimage', 'true')
			}
			for (let i = 0; i < lo.size($ps); i++) {
				let $p = $ps[i]
				if (!lo.size($p.style)) {
					$p.style.margin = '0px'
				}
			}
			lo.set(message, 'text', dom.body.innerHTML)
			console.log('uploaded base64 imgggggg', dom.body.innerHTML)
		}
		await flow.map(blobs, 5, async (blob) => {
			let {url, path, type} = blob
			let file
			if (isBlobUrl(url)) file = await sb.blobUrlToFile(url)
			if (isDataUrl(url)) file = await sb.dataURLToFile(url)
			let res = await common.uploadLocalFile(file)
			if (res.error) {
				error = res.error
			}
			let newUrl = res.url
			lo.set(delta, `ops.${path}`, newUrl)
		})
		if (delta) {
			lo.set(message, 'quill_delta', JSON.stringify(delta))
			if (message.format === 'html') {
				message.text = sb.deltaToHtml(delta.ops)
			}
		}

		return {message, error}
	}

	function getAllMessageQuillDeltaBlobPaths(message) {
		let output = []

		let delta = sb.parseJSON(message.quill_delta) || {ops: []}
		lo.each(delta.ops, (op, idx) => {
			let img = lo.get(op, 'insert.image')
			if (!img) return // continue loop
			if (isBlobUrl(img) || isDataUrl(img)) {
				output.push({
					path: `${idx}.insert.image`,
					url: img,
					type: 'quill_delta',
				})
			}
		})
		return output
	}

	function isBlobUrl(url = '') {
		return url.startsWith('blob')
	}

	function isDataUrl(url = '') {
		return url.startsWith('data:')
	}

	me.onTopic = (o, cb) => pubsub.on2(o, 'topic', cb)
	me.onSyncState = (o, cb) => pubsub.on2(o, 'convo_sync_state', cb)
	me.onConvo = (o, cb) => pubsub.on2(o, 'convo', cb)
	me.onConvo2 = (o, convoid, cb) => pubsub.on2(o, 'convo.' + convoid, cb)
	me.onMessage = (o, cb) => pubsub.on2(o, 'message', cb)
	me.onMessageUpdated = (o, cb) => pubsub.on2(o, 'message_updated', cb)
	me.onDraft = (o, cb) => pubsub.on2(o, 'draft', cb)
	me.onTyping = (o, cb) => pubsub.on2(o, 'typing', cb)
	me.onUserEvent = (o, cb) => pubsub.on2(o, 'user_event', cb)
	me.onRelatedConvos = (o, cb) => pubsub.on2(o, 'related_convos', cb)

	me.markSeeingConvo = (convoid) => {
		if (!document.hasFocus) return
		_lastSeeing = Date.now()
		if (!me || !document.hasFocus) return
		return api.pingPresence({last_seen_convo_id: convoid, is_focused: document.hasFocus()})
	}

	me.markReadTopic = (id) => id && api.markReadTopic(id)

	me.markReadConvo = (convoid) => {
		if (!convoid) return
		if (convoid.endsWith('&pinned')) return
		if (convoid.startsWith('create.')) return
		if (Date.now() - (me._alreadySeen[convoid] || 0) < 2000) return
		let convo = store.matchConvo(convoid)
		if (lo.get(convo, 'touchpoint.channel') === 'call') return
		me._alreadySeen[convoid] = Date.now()
		delete me._alreadySeen[me._lastseen]
		me._lastseen = convoid

		return api.seenConvo(convoid, '*')
	}

	me._lastseen = ''
	me._alreadySeen = {}

	const listConversationIds2 = async () => {
		let today = startOfDay(new Date())
		let milestone = today.getTime() - 5 * 24 * 3600_000

		let reses = await Promise.all([
			api.listConversationIds2({min_actived_ms: milestone - 10}),
			api.listConversationIds2({max_actived_ms: milestone + 10}),
		])
		let convos1 = lo.get(reses, '0.body.conversation_metas') || []
		let convos2 = lo.get(reses, '1.body.conversation_metas') || []
		return {
			body: {
				conversations: [...convos1, ...convos2],
			},
		}
	}

	me.topic_gate = new sb.Gate(true, 'topic')
	me.fetchTopics = async (force) => {
		if (!api.getAccountId()) return
		if (!force && convo_sync_state === 'online') return
		await me.topic_gate.entry(null, true)
		if (!force && convo_sync_state === 'online') {
			me.topic_gate.open()
			return
		}
		let out = await Promise.all([api.fetchConvoUsers(), listConversationIds2()])
		let data = lo.get(out, '0.body') || {}
		var convers = lo.get(data, 'conversations') || []
		db.put(convers)
		let users = lo.get(data, 'users') || []
		let extraUsers = lo.get(data, 'extra_users') || []
		users = [...users, ...extraUsers]
		users = lo.uniqBy(users, 'id')
		let tickets = lo.get(data, 'tickets') || []
		lo.each(tickets, (ticket) => {
			ticketstore.updateTicketStore(ticket)
		})

		udb.put(users)

		let {body, error} = out[1]
		if (error) {
			me.topic_gate.open()
			convo_sync_state = 'error'
			pubsub.publish('convo_sync_state')
			return
		}
		let lastconv = null
		lo.map(body.conversations, (conv) => {
			lastconv = conv
			let current = convo_list[conv.id]

			// keep _primary_id and _related
			if (current && typeof current === 'object') {
				conv._primary_id = current.primary_id
				conv._related = current._related
			}
			convo_list[conv.id] = conv
		})
		me.rebuildGroupedTopics()
		convo_list_dirty = true
		convo_sync_state = 'online'
		pubsub.publish('convo_sync_state')
		me.topic_gate.open()
	}

	me.rebuildGroupedTopics = () => {
		convo_list_by_user = {}
		groupTopics(convo_list, convo_list_by_user)
	}

	setTimeout(async () => {
		for (;;) {
			await me.fetchTopics(true)
			await flow.sleep(300_000)
		}
	})

	setTimeout(async () => {
		// wait only 1 min for re sync when state offline
		for (;;) {
			if (convo_sync_state === 'online') {
				await flow.sleep(60_000)
				continue
			}
			await flow.sleep(60_000)
			await me.fetchTopics()
		}
	})

	me.getUserConvoTopics = (uid) => convo_list_by_user[uid]

	me.getRelatedConvos = (cid) => {
		if (!cid) return
		let meta = convo_list[cid]
		if (meta && meta._primary_id) meta = convo_list[meta._primary_id]
		if (!meta) return
		return lo.map(meta._related, (_, rid) => convo_list[rid] || {id: rid})
	}

	me.refetchTopics = () => {
		convo_sync_state = 'offline'
		pubsub.publish('convo_sync_state')
		me.fetchTopics()
	}

	me.isConvoMatchFilter = (filter, item) => {
		return checkConvoFilter(filter, item)
	}

	me.listTopics = (limit, type) => {
		if (limit == 0) return []
		if (!limit) limit = 100
		if (limit === -1) limit = 5_000
		if (convo_list_dirty) {
			let convo_by_user = {}

			if (type === 'filter') {
				filtered_convo_list = lo.filter(convo_list, (convo) => checkConvoFilter(convo_list_view_filter, convo))
				let filtered_convo_list_obj = {}
				lo.each(filtered_convo_list, (convo) => (filtered_convo_list_obj[convo.id] = convo))
				filtered_convo_list = groupTopics(filtered_convo_list_obj, convo_by_user)
			} else {
				grouped_convo_list = lo.filter(convo_list, (convo) => !convo.is_hidden && !convo.is_dismissed)
				let grouped_convo_list_obj = {}
				lo.each(grouped_convo_list, (convo) => (grouped_convo_list_obj[convo.id] = convo))
				grouped_convo_list = groupTopics(grouped_convo_list_obj, convo_by_user)
			}
			convo_list_dirty = false
		}

		if (type === 'filter') {
			return lo.take(filtered_convo_list, limit)
		}
		return lo.take(grouped_convo_list, limit)
	}

	me.listAllTopics = () => {
		if (convo_list_dirty) return me.listTopics(10)
		return grouped_convo_list
	}

	me.matchTopic = (id) => convo_list[id]

	me.matchTopicSyncState = () => convo_sync_state // chain.matchSync('topic')
	me.sendTyping = api.send_typing

	me.destroy = () => {
		ls.destroy()
		convoOfTouchpointCache = {}
	}

	// continue doing task restored from persistent disk
	lo.orderBy(lo.map(offlineActionKV), ['created'], 'asc').map((ev) => sendQ.push(ev))

	////////////////////////////////////////////////////////////////
	///////////////////////// USER STORE //////////////////////////
	//////////////////////////////////////////////////////////////

	let udb = NewObjectStore(
		account_id,
		realtime,
		pubsub,
		'user',
		async (users) => {
			let ids = lo.map(users, 'id')
			let last_modifieds = lo.map(
				users,
				(user) => user.updated || new Date(sb.getUserDateAttr(user, 'modified')).getTime() || 0,
			)
			let {body, code, error} = await api.matchUsers({ids, last_modifieds})
			if (error || code !== 200) return {error}
			let out = lo.get(body, 'users', [])
			me.subRealtime(lo.map(users, 'id'))

			// match primary user
			let primaries = []
			lo.map(out, (user) => {
				if (user.primary_id && !lo.find(primaries, (u) => u.id === user.primary_id))
					primaries.push(udb.match(user.primary_id) || {id: user.primary_id})
			})
			last_modifieds = lo.map(
				primaries,
				(user) => user.updated || new Date(sb.getUserDateAttr(user, 'modified')).getTime() || 0,
			)
			ids = lo.map(primaries, 'id')
			await me.subRealtime(ids)
			let {body: body2, code: code2, error: error2} = await api.matchUsers({ids, last_modifieds})
			if (error2 || code2 !== 200) return {error: error2}
			let out2 = lo.get(body2, 'users', [])
			out = out.concat(out2)
			return {data: out}
		},
		[],
		true,
	)

	udb.beforePublish = (userM) => {
		lo.map(userM, (user) => {
			// notify all secondary users
			if (lo.size(user.secondaries) > 0) {
				lo.map(user.secondaries, (profile) => {
					let id = profile.id
					let sec = udb.match(id)
					if (sec) {
						sec = Object.assign({}, user)
						sec.id = id
						userM[id] = sec
					}
				})
			}
		})
		return userM
	}

	me.subRealtime = async (ids) => {
		let topics = []
		let accid = api.getAccountId()
		if (!accid) return
		lo.map(ids, (id) => {
			if (!id) return
			topics.push('user_info_updated.account.' + accid + '.user.' + id)
			topics.push('user_event_created.account.' + accid + '.user.' + id)
		})
		return realtime.subscribe(topics)
	}

	me.fetchLead = async (query) => {
		let out = await api.list_user(query)
		lo.map(lo.get(out, 'body.users', []), (user) => {
			if (!user.id) return
			// dont update udb when fetchLead to get user_ids only
			if (!lo.size(user.attributes)) return
			return udb.put(user.id, user)
		})
		return out
	}

	me.fetchPresences = async () => {
		let {body, error} = await api.listPresences()
		if (error) return {error}
		let focuseduserids = lo.get(body, 'focused_user_ids', [])
		lo.map(focuseduserids, (userid) => {
			if (!udb.has(userid)) return

			var user = udb.match(userid)
			sb.setUserAttr(user, {key: 'focused', boolean: true})
			sb.setUserAttr(user, {key: 'seen', datetime: new Date(sb.now()).toISOString()})
			udb.put(user.id, user)
		})

		let onlineuserids = lo.get(body, 'online_user_ids', [])
		lo.map(focuseduserids, (userid) => {
			if (lo.includes(focuseduserids, userid)) return // ignore duplicate
			if (!udb.has(userid)) return
			var user = udb.match(userid)
			sb.setUserAttr(user, {key: 'focused', boolean: false})
			sb.setUserAttr(user, {key: 'seen', datetime: new Date(sb.now()).toISOString()})
			udb.put(user.id, user)
		})
	}

	// event
	me.matchUserEvents = (userid) => kv.match('events:' + userid)

	var cacheUserEvents = {}
	var userEventsLock = {}

	me.fetchUserEvents2 = async (userid, start_hour, end_hour) => {
		// if (!end_hour) end_hour = Math.floor(Date.now() / 3600000) - 72
		let key = userid + '.' + start_hour + '.' + end_hour
		let primary_id = userid
		await me.fetchUser(userid) // make sure user loaded
		let user = me.matchUser(userid, true)
		if (user && user.primary_id && store.me().account_id == 'acpxkgumifuoofoosble') primary_id = user.primary_id

		if (userEventsLock[key]) {
			await sb.sleep(100)
			return me.fetchUserEvents2(userid, start_hour, end_hour)
		}

		userEventsLock[key] = true
		if (cacheUserEvents[key] && Date.now() - cacheUserEvents[key].created < 10000) {
			userEventsLock[key] = false
			return cacheUserEvents[key]
		}

		let {body, error} = await api.getUserEvents2(primary_id, start_hour, end_hour)
		userEventsLock[key] = false
		if (error) return {error}
		let events = lo.get(body, 'events', [])
		kv.put('events:' + userid, events)
		cacheUserEvents[key] = {created: Date.now(), events, anchor: parseInt(body.anchor, 10) || -1}
		return {events, anchor: parseInt(body.anchor, 10) || -1}
	}

	me.matchUserNotes = (userid) => kv.match('notes:' + userid)
	me.fetchUserNotes = async (userid) => {
		let {body, error} = await api.listNotes(userid, 100)
		if (error) return {error}
		let notes = lo.get(body, 'notes', [])
		kv.put('notes:' + userid, notes)
		return {notes}
	}
	me.upsertNote = (userid, n) => (n.id ? me.updateNote(userid, n) : me.addUserNote(userid, n))
	me.addUserNote = async (userid, n) => {
		let {body: note, error} = await api.createNote(userid, n)
		//await me.fetchUserNotes(userid)
		return {note, error}
	}
	me.updateNote = async (userid, n) => {
		let {body: note, error} = await api.updateNote(userid, n)
		//await me.fetchUserNotes(userid)
		return {note, error}
	}
	me.removeNote = async (userid, noteid) => {
		let {error} = await api.removeNote(userid, noteid)
		//await me.fetchUserNotes(userid)
		return {error}
	}

	me.addUserLabel = (userid, key) => api.addUserLabel(userid, key)
	me.unlabelUser = (userid, key) => api.removeUserLabel(userid, key)
	me.restoreUser = api.restore_user

	me.removeUsers = async (userids) => {
		await flow.map(
			userids,
			async (userid) => {
				let out = await api.remove_user(userid)
				console.log('OUT', out)
			},
			5,
		)
	}

	// user
	me.updateUser = async (u) => {
		var {error} = await api.updateUser(u)
		if (error) return {error}
		var {body: user, error} = await api.getUser(u.id)
		if (error) return {error}
		await udb.put(user.id, user)
		return {user}
	}

	me.checkExistedUser = (user) => {
		if (!user.id || user.id === 'new') return api.checkExistedCreatedUser(user)
		return api.checkExistedUser(user)
	}
	me.mergeUser = api.mergeUser
	me.unLinkUser = api.unLinkUser

	me.createUser = async (u) => {
		var {body, error} = await api.createUser(u)
		if (error) return {error}
		if (!body || !body.id) return {error: 'invalid user id'}
		var {body: user, error} = await api.getUser(body.id)
		if (error) return {error}
		await udb.put(user.id, user)
		return user
	}

	me.justUpdateUser = (u) => udb.put(u.id, u)

	me.banUser = async (uid) => {
		var {error} = await api.banUser(uid)
		if (error) return {error}
		var {body: user, error} = await api.getUser(uid)
		if (error) return {error}
		await udb.put(user.id, user)
		return {user}
	}

	me.unbanUser = async (uid) => {
		var {error} = await api.unbanUser(uid)
		if (error) return {error}
		var {body: user, error} = await api.getUser(uid)
		if (error) return {error}
		await udb.put(user.id, user)
		return {user}
	}
	//me.fetchUsers = (ids, force) => udb.fetch(ids, force)
	me.fetchUsers = async (ids, force) => {
		await udb.fetch(ids, force)
		let users = lo.map(ids, (id) => udb.match(id))
		let primaryIds = []
		lo.each(users, (user) => {
			if (user && user.primary_id) primaryIds.push(user.primary_id)
		})
		if (lo.size(primaryIds)) await udb.fetch(primaryIds, force)
	}

	me.fetchUser = async (uid, force) => {
		await udb.fetch(uid, force)
		let user = udb.match(uid)
		if (user && user.primary_id) {
			let primary_id = user.primary_id
			await udb.fetch(primary_id, force)
			user = Object.assign({}, udb.match(primary_id))
			user.id = uid
		}
		return user
	}

	let lastFetchRecentCall = 0
	let groupedRecentCalls = []
	me.fetchRecentCalls = async () => {
		if (Date.now() - lastFetchRecentCall < 60000) return
		let {body, error} = await api.listRecentCalls()
		if (error) return {error}
		if (!body) body = {}
		recent_call_db = {}
		lo.map(body.records, (rec) => (recent_call_db[rec.conversation_id] = rec))
		me.onChangeRecentCall()
		lastFetchRecentCall = Date.now()
	}

	let grouped_recent_call_db = []
	me.onChangeRecentCall = () => {
		let records = lo.orderBy(lo.map(recent_call_db), ['created'], 'desc')
		let lastrecord = {}
		grouped_recent_call_db = []
		for (var i = 0; i < records.length; i++) {
			let record = records[i]
			if (
				record.direction == lastrecord.direction &&
				record.to_number == lastrecord.to_number &&
				record.from_number == lastrecord.from_number &&
				record.is_missed == lastrecord.is_missed
			) {
				lastrecord.grouped++
				continue
			}
			lastrecord = record
			lastrecord.grouped = 0
			grouped_recent_call_db.push(lastrecord)
		}
		pubsub.publish('recent_call')
	}

	me.matchRecentCalls = () => grouped_recent_call_db

	me.fetchUserByProfile = async (channel, source, id) => {
		if (!channel || !source || !id) return {error: 'empty source or id'}
		var {body: user, error} = await api.getUserByProfile(channel, source, id)
		if (error) return {error}
		if (user.id) udb.put(user.id, user)
		if (user && user.primary_id) {
			let primary = udb.match(user.primary_id)
			let userid = user.id
			if (!primary || !primary.attributes) await udb.fetch([user.primary_id])
			user = Object.assign({}, udb.match(user.primary_id))
			user.id = userid
		}
		return user
	}

	me.matchUser = (id, force) => {
		if (!id || id === '-') return undefined
		let user = udb.match(id)
		if (user && user.primary_id) {
			let primaryUser = udb.match(user.primary_id)
			if (!primaryUser) return undefined
		}
		if (force) return user
		if (user && user.primary_id) {
			user = Object.assign({}, udb.match(user.primary_id))
			user.id = id
		}
		return user
	}

	me.getUser = (o, id) => {
		pubsub.on3(o, 'user.' + id)
		if (!id || id === '-') return undefined
		let user = udb.match(id)
		if (user && user.primary_id) {
			user = Object.assign({}, udb.match(user.primary_id))
			user.id = id
		}
		return user
	}

	me.lookupUserByEmail = api.lookupUserByEmail

	me.onRecentCall = (o, cb) => pubsub.on2(o, 'recent_call')
	me.onUser = (o, cb) => pubsub.on2(o, 'user', cb)
	me.onUser2 = (o, userid, cb) => pubsub.on2(o, 'user.' + userid, cb)

	return me
}

async function listmessageapi(type, anchor, limit) {
	let convoid = type
	let xxxxxx = await api.getConversationEvents(convoid, -limit, anchor)
	let {body: events, code, error} = xxxxxx
	if (error || code !== 200) return [[], anchor, error]
	anchor = lo.get(lo.last(events), 'id')
	events = lo.map(events, (event, i) => {
		delete event.account_id
		event.created = sb.getMs(event.created)
		return {id: event.id, index: event.created, value: event}
	})
	return [events, anchor]
}

function prepareMessageEvent2(convoid, touchpoint, message, agid, userids, comment_id) {
	message = lo.cloneDeep(message)
	message.fields = message.fields || []
	if (comment_id) message.fields.push({key: 'inbox_to_comment_id', value: JSON.stringify(comment_id)})

	var idempotency = 'id' + sb.randomString(10)
	message.tos = userids // required
	touchpoint = touchpoint || {}
	let channel = touchpoint.channel || 'subiz'

	// fallback to old send message method
	var event = {
		created: sb.now(),
		id: idempotency,
		touchpoint,
		by: {id: agid, type: 'agent'},
		data: {message},
		type: 'message_sent',
		user_id: lo.first(userids),
		_sending: 'true',
	}

	// work around, wait for BE migrate
	if (convoid && channel === 'subiz') {
		lo.set(event, 'data.message.conversation_id', convoid)
		delete event.touchpoint
	}

	if (!lo.trim(message.text) && lo.size(message.attachments) === 0) return null

	// set idempotency
	message.idempotency_key = idempotency
	return event
}

function compareConvo(a, b) {
	let aactived2Min = (a._max_actived || a.actived || 0) / 120000
	let bActived2Min = (b._max_actived || b.actived || 0) / 120000
	if (bActived2Min === aactived2Min) return a.id > b.id ? -1 : 1
	return aactived2Min > bActived2Min ? -1 : 1
}

function groupTopics(metaM, convolistbyuser) {
	const SEPERATOR_KEY_TEXT = '+++++'
	const NORMAL_CHANNEL_TEXT = 'normal'
	let byTime = {}
	lo.map(metaM, (meta) => {
		let actived = Math.floor(meta.actived / 43200000) // 12 hour
		if (!byTime[actived]) byTime[actived] = []

		// if (actived < Math.floor((Date.now() - 7_600_000) / 43200000)) return
		byTime[actived].push(meta)
	})

	let out = []
	lo.map(byTime, (metas) => {
		let primaryM = {} // {cid,tkid, uid} => {}
		lo.map(metas, (meta) => {})

		// we will group convo by logic
		// if channel is email, dont group any
		// if channel is call, group all
		// remail channel will group by old logic
		lo.map(metas, (meta) => {
			if (meta.id.startsWith('us')) {
				let key = `${meta.id}${SEPERATOR_KEY_TEXT}${NORMAL_CHANNEL_TEXT}`
				if (!primaryM[key]) primaryM[key] = {}
				primaryM[key][meta.id] = true
			}
			if (meta.id.startsWith('cs')) {
				if (!meta.user_id) return
				let channel = lo.get(meta, 'touchpoint.channel')
				let key = `${meta.user_id}${SEPERATOR_KEY_TEXT}${NORMAL_CHANNEL_TEXT}`

				if (channel === 'email') key = `${meta.user_id}${SEPERATOR_KEY_TEXT}email${SEPERATOR_KEY_TEXT}${meta.id}`
				else if (channel === 'call') key = `${meta.user_id}${SEPERATOR_KEY_TEXT}call`

				if (!primaryM[key]) primaryM[key] = {}
				primaryM[key][meta.id] = true
				lo.set(convolistbyuser, [meta.user_id, meta.id], meta)

				// set tickets to child of primary metas
				if (meta.ticket_id) primaryM[key][meta.ticket_id] = true
			}
		})

		//lo.map(metas, (meta) => {
		//if (!meta.id.startsWith('tk')) return
		//let found = false
		//lo.map(meta.associated_conversations, (cid) => {
		//let convo = metaM[cid]
		//if (convo && convo.user_id && primaryM[convo.user_id]) {
		//primaryM[convo.user_id][meta.id] = true
		//}
		//})
		//if (!found) primaryM[meta.id] = {}
		//})

		let primaryMetas = lo
			.map(primaryM, (childs, id) => {
				let meta = metaM[id]
				if (lo.size(childs) == 0) {
					meta.actived_min = Math.floor(meta.actived / 120000)
					delete meta._primary_id
					delete meta._related
					return meta
				}

				let max = meta || {actived: -1}
				lo.map(childs, (_, reid) => {
					let m = metaM[reid]
					if (m) if (m.actived > max.actived) max = m
				})
				max._related = childs

				lo.map(childs, (_, reid) => {
					let m = metaM[reid]
					if (m) m._primary_id = max.id
				})
				delete max._primary_id
				if (lo.size(max._related) < 2) delete max._related

				max.actived_min = Math.floor(max.actived / 120000)
				return max
			})
			.sort(compareConvo)
		out = primaryMetas.concat(out)
	})

	return out
	// return [{].concat(out)
	//return out.reverse()
}

function checkConvoFilter(filter, convo) {
	if (!sb.isTopicIdConvo(convo.id)) return false
	if (filter.is_hidden) return convo.is_hidden || convo.is_dismissed
	// make sure filter not display hidden convo
	if (convo.is_hidden || convo.is_dismissed) return false
	if (filter.is_unread === true) {
		if (convo.is_read) return false
	}
	if (filter.is_unread === false) {
		if (!convo.is_read) return false
	}

	if (filter.channels && filter.channels.length > 0) {
		let convochannel = lo.get(convo, 'touchpoint.channel')
		let found = filter.channels.find((channel) => {
			return convochannel === channel
		})
		if (!found) return false

		if (filter.integration_ids && filter.integration_ids.length > 0) {
			let wantinte = computeInteIdFromTouchPoint(convo.touchpoint || {})
			let allInteChannels = lo.map(filter.integration_ids, computeChannelFromInteId)

			// dont check inte_id ('accc.98212312.fabikon') when channel is not facebook (example: 'email')
			if (lo.includes(allInteChannels, convochannel)) {
				let found = filter.integration_ids.find((inteid) => {
					return inteid == wantinte
				})
				if (!found) return false
			}
		}
	}

	// ended
	if (filter.state) {
		if (convo.state != filter.state) return false
	}

	if (filter.is_open === false) {
		if (convo.state !== 'ended') return false
	}

	if (filter.is_open === true) {
		if (convo.state === 'ended') return false
	}

	if (filter.is_replied === false) {
		return !convo.is_replied
	}

	if (filter.is_replied === true) {
		return convo.is_replied
	}

	if (filter.tags && filter.tags.length > 0) {
		let convotags = (convo.tags || '').split(',')
		let found = filter.tags.find((t) => convotags.indexOf(t) != -1)
		if (!found) return false
	}

	return true
}

const COUNT_DURATION = 1 * 7 * 86400000 // 1 week
function isConvoCountInTime(convo) {
	if (!convo.actived) return false
	return Date.now() - convo.actived <= COUNT_DURATION
}

function computeChannelFromInteId(inteid = '') {
	if (lo.endsWith(inteid, 'zalokon')) return 'zalo'
	if (lo.endsWith(inteid, 'form')) return 'form'
	if (lo.endsWith(inteid, 'fabikon')) {
		if (inteid.indexOf('instagram_') > -1) {
			return 'instagram'
		} else if (inteid.indexOf('igcomment_') > -1) {
			return 'instagram_comment'
		} else if (inteid.indexOf('fbcomment_') > -1) {
			return 'facebook_comment'
		} else {
			return 'facebook'
		}
	}
}

function computeInteIdFromTouchPoint(touchpoint = {}) {
	let account_id = api.getAccountId()
	if (touchpoint.channel === 'facebook') return `${account_id}.${touchpoint.source}.fabikon`
	if (touchpoint.channel === 'facebook_comment') return `${account_id}.fbcomment_${touchpoint.source}.fabikon`
	if (touchpoint.channel === 'zalo') return `${account_id}.${touchpoint.source}.zalokon`
	if (touchpoint.channel === 'instagram') return `${account_id}.instagram_${touchpoint.source}.fabikon`
	if (touchpoint.channel === 'instagram_comment') return `${account_id}.igcomment_${touchpoint.source}.fabikon`
	if (
		touchpoint.channel === 'google_review' ||
		touchpoint.channel === 'google_message' ||
		touchpoint.channel === 'google_question'
	)
		return `${account_id}.${touchpoint.source}.googlekon`
	return `${account_id}.${touchpoint.source}.${touchpoint.channel}`
}

export default NewConvoStore
