
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: ["icon icon-tabler icons-tabler-outline icon-tabler-phone-off",classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M0 0h24v24H0z","stroke":"none"}}),_c('path',{attrs:{"d":"M3 21L21 3M5.831 14.161A15.946 15.946 0 013 6a2 2 0 012-2h4l2 5-2.5 1.5c.108.22.223.435.345.645m1.751 2.277A11.03 11.03 0 0013.5 15.5L15 13l5 2v4a2 2 0 01-2 2 15.963 15.963 0 01-10.344-4.657"}})])
          )
        }
      }
    