import store from '@sb/store'
import sb from '@sb/util'
import {formatDistanceStrict} from 'date-fns'
import {getDateFnsLocale} from '../languages.js'

export default {
	name: 'ticket-sla-modal',
	props: ['ticket_id', 'open'],
	data() {
		return {
			saving: false,
			sla: '',
			loading: false,
			loadError: false,
			violations: [],

			hasSLA: false,
			isEdit: false,
		}
	},

	watch: {
		open() {
			if (this.open) {
				this.saving = false
				this.sla = lo.get(store.matchTicket(this.ticket_id), 'sla_policy') || ''

				let ticket = store.matchTicket(this.ticket_id)
				this.hasSLA = lo.get(ticket, 'sla_policy')
				this.loadViolations()
			}
		},
	},

	created() {
		//let ticket = store.matchTicket(this.ticket_id)
		//this.hasSLA = lo.get(ticket, 'sla_policy')
		//this.loadViolations()
	},

	methods: {
		closeModal() {
			this.$emit('close')
		},

		async loadViolations() {
			if (this.loading) return
			this.loadError = false
			this.loading = true
			let res = await store.listTicketSLAViolations(this.ticket_id)
			this.loading = false
			if (lo.get(res, 'error')) {
				this.loadError = true
				return
			}
			this.violations = lo.get(res, 'body.sla_violations') || []
		},

		onSelectSLA(sla) {
			this.sla = sla
		},

		async submit() {
			if (this.saving) return
			this.saving = true
			let res = await store.updateTicket({
				id: this.ticket_id,
				sla_policy: this.sla,
				_update_fields: ['sla_policy'],
			})
			this.saving = false
			if (lo.get(res, 'error')) {
				this.$showError(lo.get(res, 'error') || this.$t('failed'))
				return
			}
			this.$showSuccess(this.$t('success'))
			if (this.hasSLA) {
				this.isEdit = false
				this.loadViolations()
			} else {
				this.$emit('close')
			}
		},

		renderContent() {
			if (!this.hasSLA) return
			if (this.loading && !this.loadError) {
				return (
					<div class='text__muted'>
						<em>{this.$t('loading')}...</em>
					</div>
				)
			}
			if (!this.loading && this.loadError) {
				return (
					<div>
						<span class='text__danger'>{this.$t('error_occurs_when_load_data')}.</span>{' '}
						<a href='javascript:;' vOn:click={this.loadViolations}>
							{this.$t('reload')}
						</a>
					</div>
				)
			}
			if (!lo.size(this.violations)) {
				return <div class='text__muted'>{this.$t('no_violations')}</div>
			}

			let firsts = []
			let everys = []
			let periods = []
			let resolutions = []
			lo.each(this.violations, (violation) => {
				if (violation.target === 'first_response_sec') firsts.push(violation)
				if (violation.target === 'every_response_sec') everys.push(violation)
				if (violation.target === 'periodic_update_sec') periods.push(violation)
				if (violation.target === 'resolution_sec') resolutions.push(violation)
			})
			let violations = [...firsts, ...everys, ...periods, ...resolutions]
			return (
				<Fragment>
					{lo.map(violations, (violation) => {
						let type = this.$t('undefined')
						let $desc = null
						let $but = null
						if (violation.target === 'first_response_sec') {
							type = this.$t('first_response')
							$desc = (
								<Fragment>
									{this.$t('expect_response_at')} <Time class='text__lowercase' time={violation.violation_at} />
								</Fragment>
							)
							$but = this.$t('but_not_replied_yet')
							if (violation.required_sec && violation.actual_sec) {
								$but = this.$t('but_replied_late', [
									formatDistanceStrict(violation.required_sec * 1000, violation.actual_sec * 1000, {
										locale: getDateFnsLocale(),
									}),
								])
							}
						}
						if (violation.target === 'every_response_sec') {
							type = this.$t('next_response')
							$desc = (
								<Fragment>
									{this.$t('expect_response_at')} <Time class='text__lowercase' time={violation.violation_at} />
								</Fragment>
							)
							$but = this.$t('but_not_replied_yet')
							if (violation.required_sec && violation.actual_sec) {
								$but = this.$t('but_replied_late', [
									formatDistanceStrict(violation.required_sec * 1000, violation.actual_sec * 1000, {
										locale: getDateFnsLocale(),
									}),
								])
							}
						}
						if (violation.target === 'periodic_update_sec') {
							type = this.$t('periodic_response')
							$desc = (
								<Fragment>
									{this.$t('expect_response_at')} <Time class='text__lowercase' time={violation.violation_at} />
								</Fragment>
							)
							$but = this.$t('but_not_replied_yet')
							if (violation.required_sec && violation.actual_sec) {
								$but = this.$t('but_replied_late', [
									formatDistanceStrict(violation.required_sec * 1000, violation.actual_sec * 1000, {
										locale: getDateFnsLocale(),
									}),
								])
							}
						}
						if (violation.target === 'resolution_sec') {
							type = this.$t('close_ticket')
							$desc = (
								<Fragment>
									{this.$t('expect_close_ticket_at')} <Time class='text__lowercase' time={violation.violation_at} />
								</Fragment>
							)
							$but = this.$t('but_not_close_ticket_yet')
							if (violation.required_sec && violation.actual_sec) {
								$but = this.$t('but_clost_ticket_late', [
									formatDistanceStrict(violation.required_sec * 1000, violation.actual_sec * 1000, {
										locale: getDateFnsLocale(),
									}),
								])
							}
						}

						return (
							<div class='d-flex mb-3' key={violation.id}>
								<Icon name='x' size='16' class='text__danger mt-2' />
								<div class='ml-3'>
									<div>{type}</div>
									<div class='text__sm text__muted'>
										{$desc}. {$but}
									</div>
								</div>
							</div>
						)
					})}
				</Fragment>
			)
		},

		renderContentLabel() {
			if (!this.hasSLA) return
			if (this.loading || this.loadError) {
				return <div class='mb-2 text__semibold'>{this.$t('violations')}</div>
			}

			return (
				<div class='mb-2 text__semibold'>
					<span class='text__danger'>{lo.size(this.violations)}</span> {this.$t('violations').toLowerCase()}
				</div>
			)
		},
	},

	render() {
		let cls = 'modal'
		if (!this.open) cls += ' modal__hide'

		let slas = lo.filter(store.matchSLA(), (sla) => {
			if (sla.archived) return false
			let ticketTypeId = lo.get(store.matchTicket(this.ticket_id), 'type_id') || ''
			// sla with no type_id  is used for default
			if (!sla.type_id) {
				return ticketTypeId.startsWith('ttdefault')
			}
			return ticketTypeId === sla.type_id
		})
		slas = lo.orderBy(slas, ['number_of', 'created'], ['asc', 'asc'])
		let items = lo.map(slas, (sla) => {
			return {
				id: sla.id,
				label: sla.name,
				disabled: sla.archived,
			}
		})
		let $empty_content = (
			<div class='text__muted text__center pt-4 pl-4 pr-4' style='height: 80px'>
				{this.$t('no_sla_for_this_ticket_type')}. <a href='javascript:;'>{this.$t('create_new')}</a>
			</div>
		)

		return (
			<div class={cls}>
				<div class='modal__overlay' vOn:click={this.closeModal}></div>
				<div class='modal__container'>
					<div class='modal__background'>
						<div class='modal__content' style='padding: 20px; width: 450px;'>
							<div class='d-flex align-items-center mb-4'>
								<div class='text__lg text__semibold'>{this.$t('sla_policy')}</div>
								<Icon name='x' class='btn__close ml-auto' vOn:click={this.closeModal} />
							</div>
							<div class='d-flex align-items-center mb-2'>
								<div class='text__semibold'>{this.$t('policy')}</div>
								{this.hasSLA && (
									<a href='javascript:;' class='ml-auto' vOn:click={() => (this.isEdit = !this.isEdit)}>
										{this.isEdit ? this.$t('cancel') : this.$t('edit')}
									</a>
								)}
							</div>
							<div class='d-flex '>
								<Dropdown
									mode='input'
									class='flex__1'
									disabled={this.hasSLA && !this.isEdit}
									items={items}
									placeholder={this.$t('sla_unselected')}
									selected={this.sla}
									empty_content={$empty_content}
									vOn:select={(item) => this.onSelectSLA(item.id)}
								/>
								{this.hasSLA && this.isEdit && (
									<div class='btn btn__sm btn__primary ml-3' disabled={this.saving} vOn:click={this.submit}>
										{this.$t('apply')}
									</div>
								)}
							</div>

							<div class='mt-4'>{this.renderContentLabel()}</div>
							<div style='height: 250px; width: 100%; position: relative; overflow: auto'>{this.renderContent()}</div>
							{!this.hasSLA && (
								<div class='text__right'>
									<div class='btn btn__sm btn__primary' disabled={this.saving} vOn:click={this.submit}>
										{this.$t('apply')}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	},
}
