import SendEmailWindow from './send_email_window.js'
import sb from '@sb/util'

const FULL_WIDTH = 550
const MINI_WIDTH = 300
const GAP = 30
const MAX_DISPLAY = 2

export default {
	data() {
		return {
			uids: [],
			minis: {},
		}
	},

	mounted() {
		//this.fillDefault({from: 'trandduck@gmail.shop'})
		this.$root.$on('show_global_email', this.onShowGlobalEmail)
	},

	destroyed() {
		this.$root.$off('show_global_email', this.onShowGlobalEmail)
	},

	methods: {
		renderSingleEmailWindown(id, idx) {
			let width = this.minis[id] ? MINI_WIDTH : FULL_WIDTH
			let gap = 60
			if (idx) {
				for (let i = 0; i < idx; i++) {
					let currentId = lo.get(this.uids, i)
					console.log('currentId', currentId)
					let currentWidth = this.minis[currentId] ? MINI_WIDTH : FULL_WIDTH
					gap += GAP + currentWidth
				}
			}

			let style = {width: width + 'px', right: gap + 'px'}
			return (
				<SendEmailWindow
					key={id}
					ref={`email_${id}`}
					mini={this.minis[id]}
					vOn:mini={(v) => this.onChangeMini(id, v)}
					vOn:close={() => this.onClose(id)}
					style={style}
				/>
			)
		},

		onChangeMini(id, v) {
			if (v !== undefined) {
				this.minis[id] = v
				this.$forceUpdate()
				return
			}

			this.minis[id] = !this.minis[id]
			this.$forceUpdate()
		},

		async onShowGlobalEmail(params) {
			if (lo.size(this.uids) >= MAX_DISPLAY) {
				this.$showError(this.$t('doesnt_support_comppse_email_at_moment'))
				return
			}

			let uid = sb.randomString(4) + Date.now()
			this.uids.push(uid)
			await this.$nextTick()
			let $div = this.$refs[`email_${uid}`]
			if ($div) $div.onShowGlobalEmail(params)
		},

		onClose(id) {
			this.uids = lo.filter(this.uids, (uid) => uid !== id)
			delete this.minis[id]
		},
	},

	render() {
		return <div>{lo.map(this.uids, this.renderSingleEmailWindown)}</div>
	},
}
