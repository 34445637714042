
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: ["icon icon-tabler icons-tabler-outline icon-tabler-map-pin",classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M0 0h24v24H0z","stroke":"none"}}),_c('path',{attrs:{"d":"M9 11a3 3 0 106 0 3 3 0 00-6 0"}}),_c('path',{attrs:{"d":"M17.657 16.657L13.414 20.9a2 2 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"}})])
          )
        }
      }
    