
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: ["icon icon-tabler icons-tabler-filled icon-tabler-mail",classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","fill":"currentColor"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M22 7.535V17a3 3 0 01-2.824 2.995L19 20H5a3 3 0 01-2.995-2.824L2 17V7.535l9.445 6.297.116.066a1 1 0 00.878 0l.116-.066L22 7.535z"}}),_c('path',{attrs:{"d":"M19 4c1.08 0 2.027.57 2.555 1.427L12 11.797l-9.555-6.37a2.999 2.999 0 012.354-1.42L5 4h14z"}})])
          )
        }
      }
    