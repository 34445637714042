import store from '@sb/store'
import sw from '../store/sw.js'

export default {
	name: 'ask-notification',

	data() {
		return {
			hasClickClose: false,
		}
	},

	methods: {
		onClose() {
			document.removeEventListener('keyup', this.onKeyup)
			this.hasClickClose = true
		},

		async onDenied() {
			store.disablePushNotification()
			this.hasClickClose = true
		},

		async onEnableClicked() {
			store.enablePushNotification()
			this.hasClickClose = true
			await sw.requestPushNotification()
		},

		onKeyup(e) {
			if (e.key === 'Escape') return this.onClose()
		},
	},

	render() {
		if (store.shouldShowOnboarding()) return null
		if (window.Notification.permission == 'denied') return null
		if (window.Notification.permission == 'granted') return null
		if (this.hasClickClose) return null
		if (store.matchDisablePushNotification()) return null
		return (
			<div class='modal' style='z-index: 2147483647'>
				<div class='modal__overlay' vOn:click={this.onClose}></div>
				<div class='modal__container notify-popup'>
					<div class='modal-content '>
						<div class='modal-main new'>
							<div class='notify-popup__head'>
								<img src={require('./assets/img/notification.png')} />
							</div>
							<div class='mt-4 mb-4 ml-4 mr-4'>
								<p class='notify-popup__title'>{this.$t('allow_notify')}</p>
								<p class='notify-popup__desc'>{this.$t('notify_desktop_has_been_block')}</p>
								<br />
								<button vOn:click_prevent={this.onEnableClicked} class='btn btn__lg btn__primary'>
									{this.$t('recieve_new_message')}
								</button>
								<button vOn:click={this.onDenied} class='btn btn__lg btn__light ml-4'>
									{this.$t('decline')}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	},

	async created() {
		document.addEventListener('keyup', this.onKeyup)
		this.$once('hook:beforeDestroy', () => document.removeEventListener('keyup', this.onKeyup))
		// this.$once('hook:beforeDestroy', () => clearInterval(updatePermissionHandler))
	},
}
