import sb from '@sb/util'
import store from '@sb/store'
import intervalToDuration from 'date-fns/intervalToDuration'
import Edit_phone_device_modal from './settings/call-center/edit_phone_device_modal'
import Calling from './calling.js'
import Fragment from '@sb/com/fragment'

const FIFTEEN_MINS_IN_MS = 900000
const ONE_HOUR_IN_MS = 3600000
const TWO_HOURS_IN_MS = 7200000

export default {
	name: 'call-modal',
	data() {
		return {
			deviceId: '',
			callTo: '',
			callFrom: '',

			calling: false,
			waiting: false,
			updatingAgent: false,

			// error
			tab: 'call',

			user_results: [],
			searching: false,

			selectedUser: null,
			justPickedRecent: false,
			showCallStatusDropdown: false,
			mute_call_until_loading: false,
			deskphone_id: '',

			fetching: false,

			mode: '',

			isWaitMicPermission: false,
			showConfirmContinueCall: false,
			isConfirmContinueCall: false,

			isAllowZccModalOpened: false,
			//103 : Xác nhận đơn hàng / cuộc hẹn
			//105 : Thông báo giao hàng
			//106 : Thông báo chuyến bay
			//107 : Cập nhật đơn hàng
			zccReasonCode: 103,
			zccSending: false,
		}
	},

	mounted() {
		this.$root.$on('show_call_modal', this.openModal)
		this.$root.$on('hide_call_modal', this.hideModal)
		store.onUser(this, () => this.mode == 'phone' && this.$forceUpdate())
		store.onRecentCall(this, () => this.mode == 'phone' && this.$forceUpdate())
		let $modal = this.$refs.modal
		if ($modal) $modal.addEventListener('keyup', this.onKeyup)
	},

	beforeDestroy() {
		this.$root.$off('show_call_modal', this.openModal)
		this.$root.$off('hide_call_modal', this.hideModal)

		let $modal = this.$refs.modal
		if ($modal) $modal.removeEventListener('keyup', this.onKeyup)
	},

	methods: {
		onKeyup(e) {
			switch (e.keyCode) {
				case 27: // ESC
					this.hideModal()
					break
				case 13: // ENTER
					this.makeCall()
					break
			}
		},

		toggle() {
			if (this.mode == '') {
				this.mode = 'phone'
				this.focusNumberInput()
			} else if (this.mode == 'phone') this.mode = ''
			else if (this.mode == 'calling_expanded') this.mode = 'calling_minimized'
			this.openModal()
		},

		openModal(params) {
			if (this.mode == '') this.mode = 'phone'
			this.focusNumberInput()
			this.setDefaultData(params)
			this.callFrom = this.pickOutboundNumber(this.callTo)
			if (params && params.call_immediately) this.makeCall()
		},

		setDefaultData(params = {}) {
			this.selectedUser = null
			let lastCallDevice = lo.get(store.me(), 'dashboard_setting.last_call_device')

			if (params.device_id) {
				this.deviceId = params.device_id
			} else if (lastCallDevice) {
				this.deviceId = lastCallDevice
			} else {
				let device =
					lo.find(
						store.matchPhoneDevice(),
						(device) => device.status !== 'unavailable' && lo.includes(device.bind_to_agents, store.me().id),
					) || {}
				this.deviceId = device.id || 'webphone'
			}
			// select webphone when current device was deleted
			let currentDevice = store.matchPhoneDevice()[this.deviceId]
			if (!currentDevice && this.deviceId !== 'webphone') {
				this.deviceId = 'webphone'
			}

			if (params.from_number) this.callFrom = params.from_number

			this.campaignId = params.campaign_id
			this.outboundCallEntryId = params.outbound_call_entry_id
			this.callTo = params.call_to || ''
			if (this.callTo) this.tab = 'call'
		},

		hideModal() {
			if (this.mode == 'phone') this.mode = ''
			else if (this.mode.startsWith == 'calling_') this.mode = 'calling_minimized'
		},

		renderIntegrationSelection() {
			let number
			let lastCallNumber = lo.get(store.me(), 'dashboard_setting.last_call_number')
			let defaultOutboundNumber = ''
			let defaultOutboundNumberId = ''
			if (this.deviceId && this.deviceId !== 'webphone') {
				let currentDevice = lo.find(store.matchPhoneDevice(), (device) => device.id === this.deviceId) || {}
				defaultOutboundNumber = lo.get(currentDevice, 'default_outbound_number', '')
				if (defaultOutboundNumber) defaultOutboundNumberId = `store.me().account_id.${defaultOutboundNumber}.call`
			}

			if (lo.find(store.matchIntegration(), (inte) => inte.id === defaultOutboundNumberId)) {
				number = defaultOutboundNumber
			} else if (lastCallNumber) {
				number = lastCallNumber
			} else {
				let inte =
					lo.find(
						store.matchIntegration(),
						(inte) => inte.connector_type === 'call' && inte.state === 'activated' && !inte.disabled,
					) || {}
				number = getCallNumberFromInteId(inte.id)
			}
			let intes = lo.filter(
				store.matchIntegration(),
				(inte) =>
					inte.connector_type === 'call' && (inte.state === 'activated' || inte.state === 'pending') && !inte.disabled,
			)
			let items = lo.map(intes, (inte) => ({
				img: inte.logo_url,
				id: getCallNumberFromInteId(inte.id),
				label: sb.getNumberDisplayName(inte),
			}))

			let selectedInte = store.matchIntegration()[`${store.me().account_id}.${number}.call`] || {}
			let $text = (
				<Fragment>
					<img2 src={selectedInte.logo_url} emoji_size='xs' />
					<div class='ml-2 text__truncate'>
						{sb.getNumberDisplayName(selectedInte, true)} {selectedInte.state === 'pending' && this.$t('not_ready')}
					</div>
				</Fragment>
			)

			if (!number) {
				$text = <em>{this.$t('unselected')}</em>
			}
			let dropdown_style = ''
			if (this.updatingAgent)
				dropdown_style = 'cursor: default; background: #e9ecef; pointer-events: none; opacity: 0.5;'

			return (
				<div class='mt-3 mb-3 d-flex align-items-center' style='justify-content: space-between;'>
					<div style='margin-top: 0' class='mr-2 session__header'>
						{this.$t('default_outbound_number')}
					</div>
					<div style='display: inline-block; max-width: 200px'>
						<Dropdown
							dropdown_width={250}
							mode='custom'
							right
							items={items}
							selected={`${store.me().account_id}.${number}.call`}
							vOn:select={(item) => this.onSelectIntegration(item.id)}
							disabled={this.updatingAgent}
						>
							<div class='btn btn__light' style={`font-size: 15px; ${dropdown_style}`}>
								<div class='d-flex align-items-center justify-content-center' style='max-width: 140px;'>
									{$text}
									<Icon name='chevron-down' class='ml-1' stroke-width='2' size='14' />
								</div>
							</div>
						</Dropdown>
					</div>
				</div>
			)
		},

		renderIntegrationSelection2() {
			let number
			let lastCallNumber = lo.get(store.me(), 'dashboard_setting.last_call_number')
			let defaultOutboundNumber = ''
			let defaultOutboundNumberId = ''
			if (this.deviceId && this.deviceId !== 'webphone') {
				let currentDevice = lo.find(store.matchPhoneDevice(), (device) => device.id === this.deviceId) || {}
				defaultOutboundNumber = lo.get(currentDevice, 'default_outbound_number', '')
				if (defaultOutboundNumber) defaultOutboundNumberId = `store.me().account_id.${defaultOutboundNumber}.call`
			}

			if (lo.find(store.matchIntegration(), (inte) => inte.id === defaultOutboundNumberId)) {
				number = defaultOutboundNumber
			} else if (lastCallNumber) {
				number = lastCallNumber
			} else {
				let inte =
					lo.find(
						store.matchIntegration(),
						(inte) => inte.connector_type === 'call' && inte.state === 'activated' && !inte.disabled,
					) || {}
				number = getCallNumberFromInteId(inte.id)
			}
			// unselect number when current inte was deleted
			let currentInte = store.matchIntegration()[`${store.me().account_id}.${number}.call`] || {}
			if (currentInte.state !== 'activated') {
				number = ''
			}

			if (this.callFrom) number = this.callFrom

			let intes = lo.filter(
				store.matchIntegration(),
				(inte) =>
					inte.connector_type === 'call' && (inte.state === 'activated' || inte.state === 'pending') && !inte.disabled,
			)
			let items = lo.map(intes, (inte) => ({
				img: inte.logo_url,
				id: getCallNumberFromInteId(inte.id),
				label: sb.getNumberDisplayName(inte),
			}))

			items.push({
				id: 'random',
				label: this.$t('random'),
				group: this.$t('autopick'),
			})
			items.push({
				id: 'samenet',
				label: this.$t('same_network'),
				group: this.$t('autopick'),
			})

			let strategy = lo.get(store.me(), 'dashboard_setting.outbound_numbers_picker', '')
			let $extratext = <div>{this.callFrom}</div>
			if (strategy == 'samenet') $extratext = this.$t('same_network')
			if (strategy == 'random') $extratext = this.$t('random')

			let selectedInte = store.matchIntegration()[`${store.me().account_id}.${number}.call`] || {}
			let $text = (
				<Fragment>
					<div class='text__truncate link link__secondary'>
						{$extratext} {selectedInte.state === 'pending' && this.$t('not_ready')}
						{(strategy == 'random' || strategy == 'samenet') && <span>&nbsp;-&nbsp;{this.callFrom}</span>}
					</div>
				</Fragment>
			)

			let dropdown_style = ''
			if (this.updatingAgent)
				dropdown_style = 'cursor: default; background: #e9ecef; pointer-events: none; opacity: 0.5;'
			return (
				<div class='mt-3 mb-2 ml-4 mr-4 d-flex align-items-center'>
					<span v-tooltip={this.$t('call_center.numbers')}>
						<Icon name='device-sim' class='text__muted mr-2' style='margin-top: -4px' stroke-width='2' size='18' />
					</span>
					<Dropdown
						dropdown_width={250}
						mode='custom'
						right
						items={items}
						selected={strategy}
						vOn:select={(item) => this.onSelectIntegration2(item.id)}
						disabled={this.updatingAgent}
					>
						<div style={`font-size: 14px; ${dropdown_style}`}>
							<div class='d-flex align-items-center justify-content-center'>
								{$text}
								<Icon name='chevron-down' class='ml-1' stroke-width='2' size='14' />
							</div>
						</div>
					</Dropdown>
				</div>
			)
		},

		pickOutboundNumber(tonumber) {
			let strategy = lo.get(store.me(), 'dashboard_setting.outbound_numbers_picker')
			let numbers = lo
				.filter(
					store.matchIntegration(),
					(inte) => inte.connector_type === 'call' && inte.state === 'activated' && !inte.disabled,
				)
				.map((inte) => getCallNumberFromInteId(inte.id))

			if (strategy == 'random') {
				if (lo.size(numbers) == 0) return ''
				return numbers[Math.floor(Math.random() * numbers.length)]
			}

			if (strategy == 'samenet') {
				let tonumberisp = getVietnamPhoneISP(tonumber)
				if (tonumberisp == 'other') {
					return lo.get(store.me(), 'dashboard_setting.last_call_number', numbers[0]) || '' // main number
				}
				let found = lo.find(numbers, (number) => getVietnamPhoneISP(number) == tonumberisp)
				if (found) return found
				return lo.get(store.me(), 'dashboard_setting.last_call_number', numbers[0]) || ''
			}

			if (!numbers) return ''
			return strategy || lo.get(store.me(), 'dashboard_setting.last_call_number', numbers[0]) || ''
		},

		async onSelectIntegration(number) {
			let out = await store.fetchAgent(store.me().id)
			let me = lo.cloneDeep(out.body)
			if (!me || !me.id) return this.$showError(out.error)
			delete me.account
			lo.set(me, 'dashboard_setting.last_call_number', number)
			this.updatingAgent = true
			await store.updateAgent({...me, _update_fields: ['dashboard_setting']})
			this.updatingAgent = false
			this.$forceUpdate()
		},

		async onSelectIntegration2(number) {
			let out = await store.fetchAgent(store.me().id)
			let me = lo.cloneDeep(out.body)
			if (!me || !me.id) return this.$showError(out.error)
			delete me.account
			lo.set(me, 'dashboard_setting.outbound_numbers_picker', number)
			this.updatingAgent = true
			await store.updateAgent({...me, _update_fields: ['dashboard_setting']})
			this.updatingAgent = false

			this.callFrom = this.pickOutboundNumber(this.callTo)
			this.focusNumberInput()
			this.$forceUpdate()
		},

		async saveAgentCallDashboardSettings() {
			let out = await store.fetchAgent(store.me().id)
			let me = lo.cloneDeep(out.body)
			if (!me || !me.id) return this.$showError(out.error)
			delete me.account
			lo.set(me, 'dashboard_setting.last_call_device', this.deviceId)
			store.updateAgent({...me, _update_fields: ['dashboard_setting']})
		},

		renderPhoneDeviceSelection() {
			let devices = lo.filter(
				store.matchPhoneDevice(),
				(device) => device.type != 'webphone' && device.id && lo.includes(device.bind_to_agents, store.me().id),
			)
			let items = lo.map(devices, (device) => {
				let $dot = <div class='dot dot__success' style='margin-top: 0' />
				if (device.status === 'unavailable') $dot = <div class='dot dot__danger' style='margin-top: 0' />
				return {
					id: device.id,
					label: device.name,
					icon: $dot,
				}
			})
			if (!lo.get(store.me(), 'dashboard_setting.webphone_disabled')) {
				items.push({
					id: 'webphone',
					label: 'Webphone',
					icon: <div class='dot dot__success' style='margin-top: 0' />,
				})
			}

			let selectedDevice = store.matchPhoneDevice()[this.deviceId] || {}
			let $text = <em>{this.$t('unselected')}</em>
			if (this.deviceId)
				$text = (
					<div style='width: 50px' class='text__truncate' title={selectedDevice.name}>
						{selectedDevice.name}
					</div>
				)
			if (this.deviceId === 'webphone') $text = 'Webphone'

			return (
				<Dropdown
					dropdown_width={180}
					class='ml-4'
					mode='custom'
					items={items}
					vOn:select={(item) => (this.deviceId = item.id)}
				>
					<div class='d-inline-flex align-items-center text__muted clickable' style='font-size: 13px'>
						{$text}
						<Icon name='chevron-down' class='ml-1' stroke-width='2' size='14' />
					</div>
				</Dropdown>
			)
		},

		renderSelection() {
			return <div class='d-flex align-items-center'></div>
		},

		checkFormValidated() {
			let result = true
			if (!this.callTo) {
				result = false
				this.focusNumberInput()
				return
			}

			return result
		},

		async makeCall() {
			this.saveAgentCallDashboardSettings()

			if (this.calling) return
			if (!this.checkFormValidated()) return
			let device = store.matchPhoneDevice()[this.deviceId]
			// auto use webphone if cast to appphone
			if (!device || device.type == 'webphone' || device.type === 'appphone') {
				device = null
			}

			let mic = store.checkMic()
			let micTimeout = await mic.timeout
			if (micTimeout === 'Not_authorized') this.isWaitMicPermission = true
			let micResult
			if (this.isWaitMicPermission) micResult = await mic.result
			else micResult = micTimeout
			this.isWaitMicPermission = false
			if (micResult === undefined && !this.isConfirmContinueCall && this.deviceId == 'webphone') {
				this.showConfirmContinueCall = true
				return
			}

			let number
			let lastCallNumber = lo.get(store.me(), 'dashboard_setting.last_call_number')
			let defaultOutboundNumber = ''
			let defaultOutboundNumberId = ''
			if (this.deviceId && this.deviceId !== 'webphone') {
				let currentDevice = lo.find(store.matchPhoneDevice(), (device) => device.id === this.deviceId) || {}
				defaultOutboundNumber = lo.get(currentDevice, 'default_outbound_number', '')
				if (defaultOutboundNumber) defaultOutboundNumberId = `store.me().account_id.${defaultOutboundNumber}.call`
			}

			if (lo.find(store.matchIntegration(), (inte) => inte.id === defaultOutboundNumberId)) {
				number = defaultOutboundNumber
			} else if (lastCallNumber) {
				number = lastCallNumber
			} else {
				let inte =
					lo.find(store.matchIntegration(), (inte) => inte.connector_type === 'call' && inte.state === 'activated') ||
					{}
				number = getCallNumberFromInteId(inte.id)
			}
			if (this.callFrom) number = this.callFrom

			// unselect number when current inte was deleted
			let currentInte = store.matchIntegration()[`${store.me().account_id}.${number}.call`] || {}
			if (currentInte.state !== 'activated') {
				number = ''
			}

			if (number == '') return this.$showError(this.$t('integration_selected_error'))

			this.calling = true
			let isZcc = lo.get(currentInte, 'sip_provider') === 'zcc'
			if (isZcc) {
				this.calling = false
				this.waiting = true
				let res = await store.checkUserAllowZccCall({user_id: this.callTo, oa_id: number})
				this.waiting = false

				let zaloCode = lo.get(res, 'body.zalo_call_consent.code')
				// code = 3: User rejected request
				// code = 8: Waiting for user approve
				let isError = res.error || zaloCode === 20 || zaloCode == 16 || zaloCode === 3
				if (zaloCode === 8) {
					return this.$showError(this.$t('waiting_zalo_user_to_approve_consent'), 5_000)
				}
				if (isError) {
					this.isAllowZccModalOpened = true
					this.currentZccParams = {user_id: this.callTo, oa_id: number}
					return
				}
			}

			if (!device) {
				this.calling = false
				this.waiting = true
				await store.makeWebCall(this.callTo, number, this.campaignId, this.outboundCallEntryId)
				// reset campaign_id and outboundCallEntryId, make sure next time click on call button, it's not use existed campaign_id
				this.campaignId = ''
				this.outboundCallEntryId = ''

				this.waiting = false
				this.isConfirmContinueCall = false
				return
			}

			this.waiting = true
			let res = await store.makeCall({
				touchpoint: {channel: 'call', source: number, id: this.callTo},
				direction: 'outbound',
				device_id: this.deviceId,
				campaign_id: this.campaignId,
				outbound_call_entry_id: this.outboundCallEntryId,
			})
			// reset campaign_id and outboundCallEntryId, make sure next time click on call button, it's not use existed campaign_id
			this.campaignId = ''
			this.outboundCallEntryId = ''

			this.waiting = false
			this.isConfirmContinueCall = false
			this.calling = false
			this.$showSuccess(this.$t('call_was_cast_to_device_successfully', [device.name]))
			if (res.error) return this.$showError(res.error)
			this.hideModal()
		},

		async focusNumberInput() {
			this.$refs.input && this.$refs.input.focus()
			await this.$nextTick()
			this.$refs.input && this.$refs.input.focus()
		},

		onChangeCallTo(e) {
			this.callTo = e.target.value
			if (this.callToError && this.callTo) {
				this.callToError = ''
			}
			this.doSearch(this.callTo)

			this.callFrom = this.pickOutboundNumber(this.callTo)
			if (!this.callFrom) this.callFrom = lo.get(store.me(), 'dashboard_setting.webphone_disabled')
		},

		renderCast() {
			let devices = lo.filter(
				store.matchPhoneDevice(),
				(device) =>
					device.type != 'webphone' &&
					device.type != 'appphone' &&
					device.id &&
					(!device.disabled || device.disabled === 0) &&
					lo.includes(device.bind_to_agents, store.me().id),
			)
			let items = lo.map(devices, (device) => {
				let $dot = <div class='dot dot__lg dot__success' style='margin-top: 0' />
				if (device.status === 'unavailable') $dot = <div class='dot__lg dot dot__warning' style='margin-top: 0' />
				return {
					id: device.id,
					label: `${device.name}`,
					icon: $dot,
					group: this.$t('call_cast_device'),
				}
			})

			if (!lo.get(store.me(), 'dashboard_setting.webphone_disabled')) {
				items.push({
					id: 'webphone',
					label: `${this.$t('desktop')}`,
					icon: <div class='dot dot__success dot__lg' style='margin-top: 0' />,
					group: this.$t('call_cast_device'),
				})
			}

			let disabled = this.calling
			if (items == 0) return null

			let deviceName

			if (this.deviceId == 'webphone') {
				deviceName = this.$t('desktop')
			} else {
				let device = lo.find(
					store.matchPhoneDevice(),
					(device) =>
						device.type != 'webphone' &&
						device.type != 'appphone' &&
						device.id &&
						device.id == this.deviceId &&
						(!device.disabled || device.disabled === 0) &&
						lo.includes(device.bind_to_agents, store.me().id),
				)
				deviceName = lo.get(device, 'name')
			}

			return (
				<Dropdown
					dropdown_width={200}
					disabled={disabled}
					right
					mode='custom'
					items={items}
					vOn:select={this.onSelectCallBy}
				>
					<a href='#' style='display: flex; align-items: center; margin-top: 10px'>
						{deviceName}
						<Icon name='cast' stroke-width='2' size='18' style='margin-top: -2px; margin-left: 5px' />
					</a>
				</Dropdown>
			)
		},

		onSelectCallBy(item) {
			this.deviceId = item.id
			this.$forceUpdate()
			this.makeCall()
		},

		renderTab() {
			let me = store.me()

			let deadDeskPhone = lo.find(
				store.matchPhoneDevice(),
				(device) =>
					device.type !== 'webphone' &&
					lo.includes(device.bind_to_agents, me.id) &&
					!device.disabled &&
					device.status === 'unavailable' &&
					device.type !== 'appphone',
			)

			let goodDeskPhone = lo.find(
				store.matchPhoneDevice(),
				(device) =>
					device.type !== 'webphone' &&
					lo.includes(device.bind_to_agents, me.id) &&
					!device.disabled &&
					device.status !== 'unavailable' &&
					device.type !== 'appphone',
			)

			let $status_call = (
				<span
					v-tooltip={this.$t('one_or_more_device_are_offline')}
					class='error__connected'
					style='vertical-align: top;'
				>
					{this.$t('error_connected')}
				</span>
			)
			if (!deadDeskPhone) {
				$status_call = null
				if (goodDeskPhone) {
					$status_call = <div style='display: inline-block' class='ml-2 dot dot__success dot__lg'></div>
				}
			}

			let mute_call_until = lo.get(me, 'dashboard_setting.mute_call_until', 0)
			if (mute_call_until > Date.now())
				$status_call = (
					<Icon
						name='phone-off'
						class='ml-2 text__dark'
						size='16'
						stroke-width={2}
						style='margin-top: -4px'
						v-tooltip={this.$t('not_disturb')}
					/>
				)

			return (
				<div class='tab' style='width: 100%; margin-bottom: 0;'>
					<div
						class={{tab__item: true, tab__item__active: this.tab === 'call'}}
						vOn:click={() => {
							this.tab = 'call'
							this.selectedUser = null
							this.callFrom = this.pickOutboundNumber(this.callTo)
							this.focusNumberInput()
						}}
					>
						<span class='tab__name'>{this.$t('call')}</span>
					</div>
					<div
						class={{tab__item: true, tab__item__active: this.tab === 'device'}}
						vOn:click={() => (this.tab = 'device')}
					>
						<span class='tab__name'>{this.$t('setting')}</span> {$status_call}
					</div>

					<div style='flex: 1'></div>
					<div class='tab__item tab__item__last'>
						<Icon name='x' stroke-width={2} class='ml-auto btn__close' size='18' vOn:click={this.hideModal} />
					</div>
				</div>
			)
		},

		renderCall() {
			if (this.tab !== 'call') return null
			let text = this.$t('call_short')
			let selectedDevice = store.matchPhoneDevice()[this.deviceId] || {}
			if (this.deviceId) text = `${this.$t('call_by')} ${selectedDevice.name}`
			if (!this.deviceId || this.deviceId === 'webphone') text = `${this.$t('call_by')} ${this.$t('desktop')}`

			let $call_icon_btn = (
				<Icon name='phone' class='phone_fill_icon' stroke-width='2' size='20px' stroke='transparent' />
			)
			if (this.waiting) $call_icon_btn = <Spinner size='20' mode='light' />

			return (
				<div style='display: flex; flex-direction: column; overflow: hidden; flex: 1'>
					{this.renderIntegrationSelection2()}
					<div class='d-flex mb-2 ml-4 mr-4'>
						<div class='session__header ' style='flex: 1;'>
							{this.$t('user_phone_number_label')}
						</div>
						{this.renderCast()}
					</div>
					<div class='d-flex align-items-center ml-4 mr-4'>
						<input
							class='call_modal_input mr-1'
							ref='input'
							type='text'
							vOn:input={this.onChangeCallTo}
							value={this.callTo}
						/>
					</div>
					{this.renderSuggestion()}
					<div class='mt-3 ml-4 mr-4' style='margin-bottom: 15px'>
						<button
							type='button'
							v-tooltip={text}
							class='btn  btn__success'
							vOn:click={this.makeCall}
							style='width: 100%'
							disabled={this.waiting}
						>
							{$call_icon_btn}
						</button>
					</div>
				</div>
			)
		},

		onEdit(id) {
			this.deskphone_id = id
		},

		closeEditModal() {
			this.deskphone_id = ''
		},

		renderLastUpdate(device) {
			let $update = null
			if (device.last_status_updated) {
				$update = (
					<div class='text__muted'>
						<Time ago has_suffix time={device.last_status_updated} />
					</div>
				)
			}

			return <Fragment>{$update}</Fragment>
		},

		renderAllPhone(devices) {
			return lo.map(devices, (device) => {
				let $unconnected = (
					<div class='ml-3 error__connected' style='background: #2EA61C;' vOn:click={() => this.onEdit(device.id)}>
						{this.$t('ready')}
					</div>
				)

				if (device.status === 'unavailable') {
					$unconnected = (
						<div class='ml-3 error__connected' vOn:click={() => this.onEdit(device.id)}>
							{this.$t('unconnected')}
						</div>
					)
				}

				if (device.disabled) {
					$unconnected = (
						<div class='ml-3 error__connected' style='background: #a5a5a5' vOn:click={() => this.onEdit(device.id)}>
							{this.$t('not_used')}
						</div>
					)
				}

				// if (device.disabled) $unconnected = null
				if (device.type == 'webphone') return null
				return (
					<div class='d-flex device__item'>
						<div class='d-flex device_item__row'>
							<div class='device__name' vOn:click={() => this.onEdit(device.id)}>
								{device.name}
							</div>
							{$unconnected}
						</div>
						<div class='d-flex device_item__row'>
							<code class='device__id' vOn:click={() => this.onEdit(device.id)}>
								{device.id}
							</code>
							<div class='device_lastupdate'>{this.renderLastUpdate(device)}</div>
						</div>
					</div>
				)
			})
		},

		renderDeskphones() {
			let devices = lo.filter(
				store.matchPhoneDevice(),
				(device) =>
					device.id &&
					lo.includes(device.bind_to_agents, store.me().id) &&
					device.type !== 'webphone' &&
					device.type !== 'appphone',
			)

			return (
				<div class='mt-3'>
					{this.renderAllPhone(devices)}
					<Edit_phone_device_modal
						device_id={this.deskphone_id}
						vOn:close={this.closeEditModal}
						vOn:submit={this.closeEditModal}
					/>
				</div>
			)
		},

		async changeStatusTo(time) {
			this.mute_call_until_loading = true
			let now = Date.now()
			let out = await store.fetchAgent(store.me().id)
			let me = lo.cloneDeep(out.body)
			if (!me || !me.id) return this.$showError(out.error)
			let new_mute_call_until = now + time
			lo.set(me, 'dashboard_setting.mute_call_until', new_mute_call_until)
			let {error} = await store.updateAgent({...me, _update_fields: ['dashboard_setting']})
			if (error) return this.$showError(error)
			this.mute_call_until_loading = false
			if (time === -1000) this.showCallStatusDropdown = false
			this.$forceUpdate()
		},

		onChangeReceiveCall(params) {
			if (!params) {
				this.changeStatusTo(-1000)
				this.showCallStatusDropdown = false
			} else {
				this.showCallStatusDropdown = true
				this.changeStatusTo(900000)
			}
		},

		renderOptionCallStatusDropdown() {
			let statusItems = [
				{id: FIFTEEN_MINS_IN_MS, label: this.$t('refuse_call_in_quarter')},
				{id: ONE_HOUR_IN_MS, label: this.$t('refuse_call_in_hour')},
				{id: TWO_HOURS_IN_MS, label: this.$t('refuse_call_in_two_hours')},
			]

			let now = Date.now()
			let me = store.me()
			let mute_call_until = me.dashboard_setting.mute_call_until
			let dnd = mute_call_until > now

			let $contentStatus = <div class='text__muted'>{this.$t('call_is_mute_when_it_turn_on')}</div>
			if (dnd) {
				let timeRemaining = intervalToDuration({start: now, end: mute_call_until})
				let strTimeRemaining = `${timeRemaining.hours} ${this.$t('hour')} ${timeRemaining.minutes} ${this.$t(
					'minutes',
				)}`
				if (timeRemaining.hours === 0 && timeRemaining.minutes > 1)
					strTimeRemaining = `${timeRemaining.minutes} ${this.$t('minutes')}`
				if (timeRemaining.minutes === 1) strTimeRemaining = `${timeRemaining.minutes} ${this.$t('minute')}`

				$contentStatus = (
					<div>
						<div class='text__muted mr-2'>{this.$t('refuse_call_in')}</div>
						<Dropdown
							mode='custom'
							items={statusItems}
							right
							dropdown_width={280}
							extra_item_cls={'dropdown_item_wide'}
							vOn:select={(item) => this.changeStatusTo(item.id)}
						>
							<div class='d-inline-flex align-items-center text__primary clickable' style='whitespace: nowrap'>
								{strTimeRemaining}
								<Icon name='chevron-down' class='ml-1' stroke-width='2' size='14' />
							</div>
						</Dropdown>
					</div>
				)
			}

			return (
				<div class=''>
					<div class='d-flex align-items-center'>
						<div class='session__header ' style='flex: 1'>
							<Icon name='phone-off' class='mr-2 text__muted' size='16' />
							{this.$t('not_disturb')}
						</div>
						<Sw style='margin-top: 10px' dark height={20} green checked={dnd} vOn:change={this.onChangeReceiveCall} />
					</div>
					<div style={this.mute_call_until_loading ? 'opacity: 0.8' : ''}>{$contentStatus}</div>
				</div>
			)
		},

		renderDevice() {
			if (this.tab !== 'device') return null
			let devices = lo.filter(
				store.matchPhoneDevice(),
				(device) =>
					device.id &&
					lo.includes(device.bind_to_agents, store.me().id) &&
					device.type !== 'webphone' &&
					device.type !== 'appphone',
			)
			let $count_devices = (
				<span>
					{devices.length} {this.$t('count_device')}
				</span>
			)
			if (devices.length > 1)
				$count_devices = (
					<span>
						{devices.length} {this.$t('count_devices')}
					</span>
				)

			return (
				<div style='padding: 0 20px'>
					{this.renderOptionCallStatusDropdown()}
					{this.renderIntegrationSelection()}
					<div class='d-flex'>
						<div class='session__header mr-2'>{this.$t('phone')}</div>
						<div class='count__devices'>{$count_devices}</div>
						<div style='flex: 1'></div>
						<router-link
							target='_blank'
							class='link link__primary '
							style='margin-top: 8px'
							to={{name: 'settings.call-center'}}
						>
							<Icon name='settings' v-tooltip={this.$t('setting')} />
						</router-link>
					</div>

					<div class='text__muted'>{this.$t('manage_all_deskphone')}</div>

					{this.renderDeskphones()}
				</div>
			)
		},

		doSearch: lo.throttle(
			async function (number) {
				this.justPickedRecent = false
				this.selectedUser = null
				let _searchIndex = this._searchIndex || 0
				_searchIndex++
				this._searchIndex = _searchIndex

				number = (number || '').trim()
				if (!number) {
					this.searching = false
					return
				}

				let agents = lo.filter(store.matchAgent(), (agent) => {
					if (agent.type !== 'agent') return false
					let name = sb
						.unicodeToAscii(agent.fullname + ';' + agent.email + ';' + agent.phone + ';' + agent.extension)
						.toLowerCase()
						.trim()
					let keyword = sb.unicodeToAscii(number).toLowerCase().trim()
					return name.includes(keyword)
				})
				agents = lo.take(agents, 3)
				this.searching = true
				let out = await store.searchUser({query: number, anchor: '', limit: 7 - agents.length})
				let userbody = out.body || {}
				if (this._searchIndex !== _searchIndex) return // outdated
				this.user_results = lo.map(agents, (ag) => ({
					document_id: ag.id,
					email: ag.email,
					name: ag.fullname,
					part: 'contact.phone',
					phone: ag.extension + '' || ag.phone || '',
					type: 'agent',
					avatar_url: ag.avatar_url,
				}))
				let hits = lo.map(userbody.hits, (hit) => Object.assign(hit, {type: 'user'}))
				hits = lo.filter(hits, (hit) => hit.phone)
				if (hits.length > 0) await store.fetchUsers(lo.map(hits, (hit) => hit.document_id))
				this.user_results = this.user_results.concat(hits)
				this.searching = false
			},
			150,
			{trailing: true},
		),

		renderResults(keyword) {
			let results = this.user_results
			if (lo.size(results) == 0 && !this.searching) {
				return (
					<div class='text__muted mt-2 mb-2'>
						Nhấn Enter hoặc bấm <span class='text__success'>📞</span> để gọi tới <b>{keyword}</b>
					</div>
				)
			}

			return lo.map(results, (res) => {
				let $avatar = null
				let name = res.name
				if (res.type == 'user') {
					let user = store.matchUser(res.document_id)
					if (!user)
						user = {
							id: res.document_id,
							attributes: [
								{key: 'avatar_url', text: res.avatar_url},
								{key: 'fullname', text: res.name},
							],
						}
					name = sb.getUserDisplayName(user)
					$avatar = (
						<div class='search__convo_avatar'>
							<div class='convos__avatar_avt'>
								<Avatar style='display:block' user={user} size={24} />
							</div>
						</div>
					)
				} else {
					let agent = store.matchAgent()[res.document_id]
					if (agent) name = sb.getAgentDisplayName(agent)
					$avatar = (
						<div class='search__convo_avatar'>
							<div class='convos__avatar_avt'>
								<Avatar style='display:block' agent={agent} size={24} />
							</div>
						</div>
					)
				}

				let info = (res.phone || '').substr(0, 200).trim()
				let $info = []
				let normquery = sb.unicodeToAscii(lo.trim(keyword)).toLowerCase()
				if (info) {
					let i = sb.unicodeToAscii(info).toLowerCase().indexOf(normquery)
					if (i >= 0 && !this.selectedUser) {
						// dont highlight when an user is selected
						$info.push(<span>{info.substr(0, i)}</span>)
						$info.push(<span class='search__highlight'>{info.substr(i, normquery.length)}</span>)
						$info.push(<span>{info.substr(i + normquery.length)}</span>)
					} else {
						$info.push(info)
					}
				} else {
					$info = <i class='text__muted'>{this.$t('no_number')}</i>
				}

				let $name = []
				let i = sb.unicodeToAscii(name).toLowerCase().indexOf(normquery)
				if (i >= 0 && this.selectedUser) {
					// dont highlight when an user is selected
					$name.push(<span>{name.substr(0, i)}</span>)
					$name.push(<span class='search__highlight'>{name.substr(i, normquery.length)}</span>)
					$name.push(<span>{name.substr(i + normquery.length)}</span>)
				} else {
					$name.push(name)
				}

				if (!name) $name = [<span class='text__muted'>{this.$t('unnamed')}</span>]

				let $isagent = null
				if (res.type == 'agent') {
					$isagent = <Icon v-tooltip={this.$t('agent')} name='key' class='text__primary' stroke-width={2} size={14} />
				}

				return (
					<div class='call_search__item' vOn:click={(_) => this.onResClick(res)}>
						{$avatar}
						<div class='ml-3' style='overflow: hidden; flex: 1; display: flex'>
							<span class='text__truncate' style='flex:1; margin-right: 10px'>
								{$name} {$isagent}
							</span>
							{$info}
						</div>
					</div>
				)
			})
		},

		onResClick(res) {
			if (!res.phone) return null
			this.$refs.input && this.$refs.input.focus()
			if (res.recent_call) this.justPickedRecent = true
			this.selectedUser = res
			this.callTo = res.phone
			this.callInfo = null
			this.makeCall()
		},

		renderCallIcon(call) {
			let size = 18
			if (call.direction == 'outbound') {
				if (call.is_missed) {
					return <img style='width: size; height: size' src={require('./assets/img/phone/outbound_miss_call.png')} />
				}
				return <img style='width: size; height: size' src={require('./assets/img/phone/outbound_call.png')} />
			}
			if (call.is_missed) {
				return <img style='width: size; height: size' src={require('./assets/img/phone/miss_call.png')} />
			}
			return <img style='width: size; height: size' src={require('./assets/img/phone/inbound_call.png')} />
		},

		renderRecentCalls() {
			let users = lo.orderBy(store.matchRecentCalls(), 'created', 'desc')
			let $body = lo.map(users, (data) => {
				let user_id = data.user_id
				let name = ''
				let number = data.to_number
				if (data.direction == 'outbound') name = data.to_number
				else {
					name = data.from_number
					number = data.from_number || data.to_number
				}

				if (user_id) {
					if (user_id.startsWith('ag')) {
						let user = lo.get(store.matchNumberInfo(number), 'info')
						name = user.fullname
					} else {
						let user = store.matchUser(user_id)
						name = sb.getUserDisplayName(user)
					}
				} else {
					let out = lo.get(store.matchNumberInfo(number), 'info')
					if (out.type == 'agent' && out.id) {
						name = out.fullname
					}
					if (out.type == 'user') {
						if (out.id) {
							let user = store.matchUser(out.id)
							name = sb.getUserDisplayName(user)
						} else {
							if (out.fullname) name = out.fullname
						}
					}
				}

				let display_number = number
				if (data.grouped > 1) display_number += ' (' + data.grouped + ')'
				let $avatar = this.renderCallIcon(data)

				let $info = <Time class='text__muted' time={data.created} />
				let $number = <span semibold>{display_number}</span>
				if (data.is_missed)
					$number = (
						<span style={{color: '#E34545'}} semibold>
							{display_number}
						</span>
					)

				return (
					<div
						class='call_search__item'
						vOn:click={(_) => this.onResClick({type: 'user', recent_call: true, phone: number, document_id: user_id})}
					>
						{$avatar}
						<div class='ml-3' style='overflow: hidden; flex: 1; display: flex'>
							<span class='text__truncate' style='flex:1; margin-right: 10px' v-tooltip={name}>
								{$number}
							</span>
							{$info}
						</div>
					</div>
				)
			})

			if (lo.size(users) == 0) {
				$body = <div class='text__muted mt-2 mb-2'>{this.$t('cant_find_anyone')} ¯\_(ツ)_/¯</div>
			}

			let $loading = null
			if (this.searching) {
				// show loading overlay
				$loading = <Spinner size='14' mode='dark' class='ml-2' style='margin-top: -2px' />
			}
			let cls = 'call_search__body'
			return (
				<div class={cls}>
					<div class='cal_modal_sub_label'>
						{this.$t('recent_callers')}
						{$loading}
					</div>
					{$body}
				</div>
			)
		},

		renderSuggestion() {
			let $loading = null
			if (this.searching) {
				// show loading overlay
				$loading = <Spinner size='14' mode='dark' class='ml-2' style='margin-top: -2px' />
			}

			if (this.selectedUser) {
				let res = this.selectedUser
				let $avatar = null
				let name = res.name
				if (res.type == 'user') {
					let user = store.matchUser(res.document_id)
					if (!user)
						user = {
							id: res.document_id,
							attributes: [
								{key: 'avatar_url', text: res.avatar_url},
								{key: 'fullname', text: res.name},
							],
						}
					name = sb.getUserDisplayName(user)
					$avatar = (
						<div class='search__convo_avatar'>
							<div class='convos__avatar_avt'>
								<Avatar style='display:block' user={user} size={16} />
							</div>
						</div>
					)
				} else {
					let agent = store.matchAgent()[res.document_id]
					if (agent) name = sb.getAgentDisplayName(agent)
					$avatar = (
						<div class='search__convo_avatar'>
							<div class='convos__avatar_avt'>
								<Avatar style='display:block' agent={agent} size={16} />
							</div>
						</div>
					)
				}

				let $name = [name]
				if (!name) $name = [<span class='text__muted'>{this.$t('unnamed')}</span>]

				let $isagent = null
				if (res.type == 'agent') {
					$isagent = <Icon v-tooltip={this.$t('agent')} name='key' class='text__primary' stroke-width={2} size={14} />
				}

				return (
					<div class='call_search__body'>
						<div class='cal_modal_sub_label'>{this.$t('suggestion')}</div>
						<div class='text__muted mt-2 mb-2' style='font-size: 13px'>
							Nhấn Enter hoặc bấm <span class='text__success'>📞</span> để gọi tới{' '}
							<div class='d-flex align-items-center'>
								{$avatar}
								<div class='text__truncate ml-1 mr-1'>{$name}</div> {$isagent}
							</div>
						</div>
					</div>
				)
			}

			let number = (this.callTo || '').trim()
			if (this.justPickedRecent || !number) return this.renderRecentCalls()

			let cls = 'call_search__body'
			if (this.searching) cls += ' call_search__body__disabled'
			return (
				<div class={cls}>
					<div class='cal_modal_sub_label'>
						{this.$t('suggestion')}
						{$loading}
					</div>
					{this.renderResults(number)}
				</div>
			)
		},

		onChangeDisplayMode(e) {
			this.mode = e
		},

		onCancelCall(e) {
			this.isConfirmContinueCall = e
			this.showConfirmContinueCall = false
		},

		onContinueCall(e) {
			this.isConfirmContinueCall = e
			this.showConfirmContinueCall = false
			this.makeCall()
		},

		renderZccNeedPermisionModal() {
			let items = [
				{
					id: 103,
					label: 'Xác nhận đơn hàng / cuộc hẹn',
				},
				{
					id: 105,
					label: 'Thông báo giao hàng',
				},
				{
					id: 106,
					label: 'Thông báo chuyến bay',
				},
				{
					id: 107,
					label: 'Cập nhật đơn hàng',
				},
			]

			return (
				<Modal show={this.isAllowZccModalOpened} vOn:bg={() => (this.isAllowZccModalOpened = false)}>
					<div class='modal__background'>
						<div class='modal_content' style='width: 520px'>
							<div class='modal_content__main'>
								<div class='d-flex align-items-center mb-4'>
									<Icon name='alert-triangle' class='mr-2 text__danger' />
									<div class='text__semibold'>{'Không thực hiện được cuộc gọi qua Zalo'}</div>
									<Icon
										name='x'
										class='x-icon ml-auto'
										size='24'
										vOn:click={() => (this.isAllowZccModalOpened = false)}
									/>
								</div>
								<div>
									{
										'Cuộc gọi không thể thực hiện vì người dùng chưa cho phép nhận cuộc gọi từ ZaloOA. Bạn cần gửi yêu cầu cho phép nhận cuộc gọi.'
									}
								</div>
								<div class=''>
									{'Xem thêm thông tin về tính năng Gọi thoại Zalo '}
									<a
										href='https://oa.zalo.me/home/resources/guides/thong-bao-nang-cap-tinh-nang-goi-thoai-thang-102022_7741960861449996368'
										target='_blank'
									>
										{this.$t('tại đây')}
									</a>
								</div>
								<div class='mt-3'>
									<div class='mb-2'>{this.$t('reason')}</div>
									<Dropdown
										mode='input'
										items={items}
										style='width: 250px'
										selected={this.zccReasonCode}
										vOn:select={(item) => (this.zccReasonCode = item.id)}
									/>
								</div>
								<div class='mt-3'>
									<span class='text__semibold'>Lưu ý: Chi phí là 550đ / yêu cầu (bao gồm VAT)</span>
								</div>
								<div class='mt-5 d-flex align-items-center justify-content-end'>
									<div class='link link__secondary mr-4' vOn:click={() => (this.isAllowZccModalOpened = false)}>
										{this.$t('cancel')}
									</div>
									<div class='btn btn__primary' disabled={this.zccSending} vOn:click={this.sendZccPermRequest}>
										{'Gửi yêu cầu'}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)
		},

		async sendZccPermRequest() {
			if (!this.currentZccParams) return
			if (this.zccSending) return
			this.zccSending = true
			let {error} = await store.sendZccCallPermission({...this.currentZccParams, reason_code: this.zccReasonCode})
			this.zccSending = false
			if (error) return this.$showError(error)
			this.isAllowZccModalOpened = false
			this.$showSuccess('Yêu cầu gọi thoại đã được gửi. Bạn sẽ nhận được thông báo khi khách đồng ý')
		},
	},

	render() {
		let style = `position: fixed;
bottom: 74px;
right: 50px;
z-index: 500;
overflow: hidden;
border-radius: 10px;
outline: 0;
display:flex;
flex-direction: column;
width: 330px;
height: 400px;
background: white;
box-shadow: 0px 0px 0px 0px, rgb(0 0 0 / 22%) 0px 10px 40px 0px, rgb(0 0 0 / 17%) 0px 0px 16px 0px;
`

		if (this.mode != 'phone') style += 'display:none;'
		let $callbtn = null
		if (this.mode == 'phone' || this.mode == '' || this.mode == 'calling_expanded')
			$callbtn = <CallButton vOn:toggle={this.toggle} />
		return (
			<div>
				{this.isWaitMicPermission && (
					<div class='modal' style='z-index: 9999;'>
						<div class='modal__overlay'></div>
					</div>
				)}
				{$callbtn}
				<Calling mode={this.mode} vOn:changeDisplayMode={this.onChangeDisplayMode} />
				<div style={style} ref='modal'>
					<div class='call_modal_header'>{this.renderTab()}</div>
					<div class='call_modal_body'>
						{this.renderCall()}
						{this.renderDevice()}
					</div>
					{this.showConfirmContinueCall && (
						<ConfirmContinueCall vOn:cancelCall={this.onCancelCall} vOn:continueCall={this.onContinueCall} />
					)}
				</div>
				{this.renderZccNeedPermisionModal()}
			</div>
		)
	},
}

function getCallNumberFromInteId(inteid = '') {
	inteid = lo.split(inteid, '.')
	return lo.get(inteid, 1, '')
}

let CallButton = {
	name: 'call-button',

	mounted() {
		store.onAccount(this, () => this.$forceUpdate())
		this.timer = setInterval(() => {
			let me = store.me()
			let mute_call_until = lo.get(me, 'dashboard_setting.mute_call_until', 0)
			if (mute_call_until > Date.now()) this.$forceUpdate()
		}, 1000)
	},

	beforeDestroy() {
		clearInterval(this.timer)
	},

	render() {
		let call_channel = lo.filter(
			store.matchIntegration(),
			(inte) => inte.state != 'deleted' && inte.connector_type === 'call',
		)
		if (call_channel.length === 0) return null
		let me = store.me()
		let mute_call_until = lo.get(me, 'dashboard_setting.mute_call_until', 0)
		let now = Date.now()
		let cls = 'global_call_button'
		if (mute_call_until > now) {
			let delta = (mute_call_until - now) / 1000 // seconds
			let hour = Math.floor(delta / 3600)
			delta = delta - hour * 3600
			let min = Math.floor(delta / 60)
			let sec = Math.floor(delta - min * 60)
			if (min < 10) min = '0' + min
			if (sec < 10) sec = '0' + sec
			let timeLeft = `${min}:${sec}`
			if (hour > 0) {
				if (hour < 10) hour = '0' + hour
				timeLeft = `${hour}:${min}:${sec}`
			}

			cls += ' global_call_button__muted'
			return (
				<div class={cls} vOn:click={(_) => this.$emit('toggle')}>
					<Icon name='phone-off' stroke-width={1.5} size='18' />
					<div class='ml-2' style='margin-top: 2px'>
						{timeLeft}
					</div>
				</div>
			)
		}

		let deadDeskPhone = lo.find(
			store.matchPhoneDevice(),
			(device) =>
				device.type !== 'webphone' &&
				lo.includes(device.bind_to_agents, me.id) &&
				!device.disabled &&
				device.status === 'unavailable' &&
				device.type !== 'appphone',
		)

		let $dot = null
		if (deadDeskPhone) {
			$dot = (
				<div v-tooltip={this.$t('one_or_more_device_are_offline')} class='webphone_disconnected'>
					!
				</div>
			)
		}

		return (
			<div class={cls} vOn:click={(_) => this.$emit('toggle')}>
				<div style='position: relative; line-height: 1'>
					{$dot}
					<Icon name='phone' size='20' />
				</div>
			</div>
		)
	},
}

let ConfirmContinueCall = {
	name: 'confirm',

	render() {
		let style = `width: 100%; height: 100%; position: absolute; background-color: rgba(0, 0, 0, 0.23);`

		return (
			<div class='d-flex justify-content-center align-items-center' style={style}>
				<div class='d-flex call_center__confirm_continue_call_modal'>
					<div>{this.$t('confirm_call_when_mic_off')}</div>
					<div class='d-flex align-items-center' style='justify-content: space-evenly;'>
						<div class='btn btn__light' vOn:click={() => this.$emit('cancelCall', false)}>
							{this.$t('cancel')}
						</div>
						<div class='btn btn__success' vOn:click={() => this.$emit('continueCall', true)}>
							{this.$t('confirm')}
						</div>
					</div>
				</div>
			</div>
		)
	},
}

function getVietnamPhoneISP(number) {
	number = number + ''
	let first3 = ''
	let first4 = ''
	if (lo.size(number) < 5) return 'other'
	if (number[0] == '8' && number[1] == '4') {
		first3 = '0' + number.substr(2, 2)
		first4 = '0' + number.substr(2, 3)
	} else if (number[0] == '0') {
		first3 = number.substr(0, 3)
		first4 = number.substr(0, 4)
	}
	if (first3 == '059' || first3 == '099' || first4 == '0199') return 'Gmobile'
	if (
		first3 == '052' ||
		first3 == '056' ||
		first3 == '058' ||
		first3 == '092' ||
		first4 == '0182' ||
		first4 == '0186' ||
		first4 == '0188'
	) {
		return 'Vietnammobile'
	}
	if (
		first3 == '070' ||
		first3 == '079' ||
		first3 == '077' ||
		first3 == '076' ||
		first3 == '078' ||
		first3 == '090' ||
		first3 == '093' ||
		first3 == '089' ||
		first4 == '0120' ||
		first4 == '0121' ||
		first4 == '0122' ||
		first4 == '0126' ||
		first4 == '0128'
	) {
		return 'Mobifone'
	}

	if (first3 == '087') {
		return 'Itelecom'
	}

	if (
		first3 == '083' ||
		first3 == '084' ||
		first3 == '085' ||
		first3 == '081' ||
		first3 == '082' ||
		first3 == '088' ||
		first3 == '091' ||
		first3 == '094' ||
		first4 == '0128' ||
		first4 == '0123' ||
		first4 == '0125' ||
		first4 == '0127' ||
		first4 == '0124' ||
		first4 == '0126' ||
		first4 == '0129'
	) {
		return 'Vinaphone'
	}

	if (
		first3 == '032' ||
		first3 == '033' ||
		first3 == '034' ||
		first3 == '035' ||
		first3 == '036' ||
		first3 == '037' ||
		first3 == '038' ||
		first3 == '039' ||
		first3 == '096' ||
		first3 == '097' ||
		first3 == '098' ||
		first3 == '086' ||
		first4 == '0169' ||
		first4 == '0168' ||
		first4 == '0167' ||
		first4 == '0166' ||
		first4 == '0165' ||
		first4 == '0164' ||
		first4 == '0163' ||
		first4 == '0162'
	) {
		return 'Viettel'
	}
	return 'other'
}
