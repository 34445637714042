const sb = require('@sb/util')
import store from '@sb/store'

import {OnelineMsg, AvatarGroup} from '@sb/com'

export default {
	name: 'user-convo-item',
	props: ['convo', 'cid'],

	created() {
		store.onConvo(this, (convoM, testtt) => {
			if (convoM[this.convo.id]) this.$forceUpdate()
		})
	},

	methods: {},

	render() {
		let convo = this.convo
		let cls = 'user_convos__convo_title'
		if (convo.id !== this.cid) cls += ' user_convos__convo_title__clickable'
		let connectorType = lo.get(convo, 'touchpoint.channel', 'subiz')

		let $subject = convo.subject
		if (!convo.subject)
			$subject = (
				<span style='font-weight: 400;' class='text__muted'>
					{this.$t('conversation')}
				</span>
			)

		let lastmsgsent = lo.get(convo, 'last_message_sent', {data: {message: lo.get(convo, 'last_message', {})}})
		let joinedAgents = lo
			.filter(convo.members, (m) => m.type === 'agent' && m.membership === 'active')
			.map((m) => store.matchAgent()[m.id])
		let $avatar = <Avatar user={this.user} size='40' class='user_convo_item_avatar' />
		if (lo.size(joinedAgents) === 1) {
			$avatar = <Avatar agent={joinedAgents[0]} size='40' class='user_convo_item_avatar' rounded nodot />
		} else if (lo.size(joinedAgents) > 1) {
			$avatar = (
				<div class='user_convo_item_avatar_wrapper'>
					<img2
						src={lo.get(joinedAgents, '0.avatar_url')}
						class='user_convo_item_avatar inside'
						title={lo.get(joinedAgents, '0.fullname')}
					/>
					<img2
						src={lo.get(joinedAgents, '1.avatar_url')}
						class='user_convo_item_avatar inside'
						title={lo.get(joinedAgents, '1.fullname')}
					/>
				</div>
			)
			$avatar = <AvatarGroup sm agents={joinedAgents} size={2} rounded />
		}
		let isConvoRead = store.isConvoRead(convo.id) || this.cid === convo.id
		let $img = (() => {
			let style = 'width: 16px; height: 16px'
			if (connectorType === 'facebook_comment') {
				return <img style={style} src={require('../assets/img/facebook_channel.svg')} alt='' />
			}
			if (connectorType === 'facebook') {
				return <img style={style} src={require('../assets/img/messenger_channel.svg')} alt='' />
			}
			if (connectorType === 'instagram' || connectorType === 'instagram_comment') {
				return <img style={style} src={require('../assets/img/instagram.svg')} alt='' />
			}
			if (connectorType === 'subiz') {
				return <img style={style} src={require('../assets/img/subiz_channel.svg')} alt='' />
			}
			if (connectorType === 'zalo') {
				return <img style={style} src={require('../assets/img/zalo_channel.svg')} alt='' />
			}
			if (connectorType === 'email') {
				return <img style={style} src={require('../assets/img/email_channel.svg')} alt='' />
			}
			if (connectorType === 'form') {
				return <img style={style} src={require('../assets/img/form.svg')} alt='' />
			}
			if (connectorType === 'google_review') {
				return <img style={style} src={require('../assets/img/google_review_channel.svg')} alt='' />
			}
			if (connectorType === 'google_message') {
				return <img style={style} src={require('../assets/img/google_message_channel.svg')} alt='' />
			}
			if (connectorType === 'google_question') {
				return <img style={style} src={require('../assets/img/google_question_channel.svg')} alt='' />
			}
		})()

		let isread = store.isConvoRead(convo.id)
		let $status = null

		let textcls = 'text__truncate '
		if (!isread) {
			if (convo.state == 'ended') {
				$status = (
					<div>
						<div style='margin-top: 0px; display: inline-block' class='dot dot__lg dot__secondary' />
					</div>
				)
			} else {
				textcls += ' text__primary'
				$status = (
					<div>
						<div style='margin-top: 0px; display: inline-block' class='dot dot__lg dot__primary' />
					</div>
				)
			}
		} else {
			if (convo.state == 'ended')
				$status = (
					<div>
						<div
							style='margin-top: 2px; display: inline-block'
							title={this.$t('completed_conversation')}
							class='convo_header__done_icon'
						/>
					</div>
				)

			textcls += ' text__muted'
		}

		let subject = lo.get(convo, 'subject') || ''
		if (lo.get(convo, 'touchpoint.channel') === 'email') {
			subject = sb.getMsgField(lastmsgsent, 'subject')
		}
		// height 24 because sometime oneline msg return nil
		let $text = (
			<div style='height:24px'>
				<OnelineMsg ev={lastmsgsent} placeholder={subject} hasAuthor convo={convo} class={textcls} />
			</div>
		)

		return (
			<div
				key={convo.id}
				class={`user_convo_item ${this.cid === convo.id ? 'active' : ''}`}
				vOn:click={(_) => this.$emit('click', convo.id)}
			>
				<div class='d-flex'>
					<div class='user_convo_item_channel_img'>{$img}</div>
					<div class='ml-3 mr-3 flex__1' style='overflow: hidden;'>
						<div class={`text__truncate text__semibold`}>{$subject}</div>
						{$text}
					</div>
					<div class='ml-auto no-shrink text__right'>
						<Time ago time={convo.actived || convo.created} class='text__muted' style='font-size: 14px' />
						{$status}
					</div>
				</div>
			</div>
		)
	},
}
