import LexicalEditor from '../../commons/lexical-editor.js'

export default {
	name: 'text-message-editor',
	props: ['isEditorFocused', 'uid', 'cid'],

	data() {
		return {
			initmsg: {},
		}
	},

	created() {
		this.initmsg = store.getConvoCacheMessage(this.cid) || {type: 'plaintext', text: ''}
	},

	methods: {
		SetQuote(ev) {
			let initmsg = lo.cloneDeep(this.initmsg)
			let attachments = lo.get(initmsg, 'attachments') || []
			attachments = lo.filter(attachments, (att) => att.type !== 'quote')
			attachments.push({type: 'quote', quote: ev})
			initmsg.attachments = attachments
			this.initmsg = initmsg
		},

		onMessageChanged: lo.throttle(
			function (message) {
				if (!message.text) return
				if (message.text === '<p></p>' || message.text === '<p><br></p>') return
				if (message.text == '<p class="sbz_lexical_paragraph"><br></p>') return
				if ((this.cid || '').startsWith('create')) return
				store.sendTyping(this.cid)
			},
			2000,
			{leading: true, trailing: false},
		),

		saveToMemCache(msg) {
			store.setConvoCacheMessage(this.cid, msg)
		},

		Focus() {
			this.$refs.editor && this.$refs.editor.Focus()
		},

		focus() {
			this.$refs.editor && this.$refs.editor.Focus()
		},
	},

	render() {
		let convo = store.matchConvo(this.cid)
		let connectorType = lo.get(convo, 'touchpoint.channel')
		let acc_id = store.me().account_id
		let fblinks = true
		if (connectorType === 'facebook_comment' || connectorType === 'facebook' || connectorType === 'instagram')
			fblinks = false
		if (acc_id !== 'acpxkgumifuoofoosble' && acc_id !== 'acqsulrowbxiugvginhw') fblinks = false

		return (
			<LexicalEditor
				plaintext
				ref='editor'
				autofocus
				class={{just_focus: this.isEditorFocused, lexical_input__white: true}}
				placeholder={this.$t('placeholder_editor')}
				initMessage={this.initmsg}
				vOn:change={(msg) => {
					this.initmsg = msg
					this.saveToMemCache(msg)
					this.onMessageChanged(msg)
				}}
				submit_on_enter
				has_like_button
				useTemplate
				transform_dynamic_field
				has_template_manage_btn
				no_preview_mode
				useSuggestion
				useProduct
				cid={this.cid}
				uid={this.uid}
				template_connector_type='subiz'
				vOn:submit={() => {
					this.$emit('submit', this.initmsg)
					this.initmsg = {text: '', type: 'plaintext'}
					this.saveToMemCache(this.initmsg)
				}}
				vOn:action={(action) => this.$emit('action', action)}
				fblinks={fblinks}
			/>
		)
	},
}
