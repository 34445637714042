var QuestionMark = require('../assets/img/tabler-icons/outline/grain.svgv')

// preload
require('../assets/img/tabler-icons/outline/user-circle.svgv')
require('../assets/img/tabler-icons/outline/message-dots.svgv')
require('../assets/img/tabler-icons/outline/circle-check.svgv')
require('../assets/img/tabler-icons/outline/receipt-2.svgv')
require('../assets/img/tabler-icons/outline/chart-pie.svgv')
require('../assets/img/tabler-icons/outline/filter.svgv')
require('../assets/img/tabler-icons/outline/brand-chrome.svgv')
require('../assets/img/tabler-icons/outline/x.svgv')
require('../assets/img/tabler-icons/outline/search.svgv')
require('../assets/img/tabler-icons/outline/grid-dots.svgv')
require('../assets/img/tabler-icons/outline/settings.svgv')
require('../assets/img/tabler-icons/outline/bell.svgv')
require('../assets/img/tabler-icons/outline/bell-ringing-2.svgv')
require('../assets/img/tabler-icons/outline/help.svgv')
require('../assets/img/tabler-icons/outline/phone.svgv')
require('../assets/img/tabler-icons/outline/phone-off.svgv')
require('../assets/img/tabler-icons/outline/upload.svgv')
require('../assets/img/tabler-icons/outline/plus.svgv')
require('../assets/img/tabler-icons/outline/check.svgv')
require('../assets/img/tabler-icons/outline/edit.svgv')
require('../assets/img/tabler-icons/outline/dots.svgv')
require('../assets/img/tabler-icons/outline/trash.svgv')
require('../assets/img/tabler-icons/outline/chevron-right.svgv')
require('../assets/img/tabler-icons/outline/chevron-down.svgv')
require('../assets/img/tabler-icons/outline/bold.svgv')
require('../assets/img/tabler-icons/outline/italic.svgv')
require('../assets/img/tabler-icons/outline/underline.svgv')
require('../assets/img/tabler-icons/outline/align-left.svgv')
require('../assets/img/tabler-icons/outline/list.svgv')
require('../assets/img/tabler-icons/outline/arrows-shuffle.svgv')
require('../assets/img/tabler-icons/outline/shopping-cart.svgv')
require('../assets/img/tabler-icons/outline/message-bolt.svgv')
require('../assets/img/tabler-icons/outline/thumb-up.svgv')
require('../assets/img/tabler-icons/filled/thumb-up.svgv')
require('../assets/img/tabler-icons/outline/map-pin.svgv')
require('../assets/img/tabler-icons/outline/list-numbers.svgv')
require('../assets/img/tabler-icons/outline/link.svgv')
require('../assets/img/tabler-icons/outline/photo.svgv')
require('../assets/img/tabler-icons/outline/paperclip.svgv')
require('../assets/img/tabler-icons/outline/mood-smile.svgv')
require('../assets/img/tabler-icons/outline/external-link.svgv')
require('../assets/img/tabler-icons/outline/circle.svgv')
require('../assets/img/tabler-icons/filled/circle.svgv')
require('../assets/img/tabler-icons/outline/antenna-bars-1.svgv')
require('../assets/img/tabler-icons/outline/chevrons-left.svgv')
require('../assets/img/tabler-icons/outline/chevrons-right.svgv')
require('../assets/img/tabler-icons/outline/calendar.svgv')
require('../assets/img/tabler-icons/outline/chevron-left.svgv')
require('../assets/img/tabler-icons/outline/shield.svgv')
require('../assets/img/tabler-icons/outline/file-plus.svgv')
require('../assets/img/tabler-icons/outline/mail.svgv')
require('../assets/img/tabler-icons/filled/mail.svgv')
require('../assets/img/tabler-icons/outline/cast.svgv')
require('../assets/img/tabler-icons/outline/alert-triangle.svgv')
require('../assets/img/tabler-icons/outline/history.svgv')
require('../assets/img/tabler-icons/outline/arrows-sort.svgv')
require('../assets/img/tabler-icons/outline/minus.svgv')
require('../assets/img/tabler-icons/outline/send.svgv')
require('../assets/img/tabler-icons/outline/tag.svgv')
require('../assets/img/tabler-icons/outline/mail.svgv')
require('../assets/img/tabler-icons/outline/corner-up-left.svgv')
require('../assets/img/tabler-icons/outline/messages.svgv')
require('../assets/img/tabler-icons/outline/checklist.svgv')
require('../assets/img/tabler-icons/outline/circle-key.svgv')
require('../assets/img/tabler-icons/outline/package.svgv')
require('../assets/img/tabler-icons/outline/browser.svgv')
require('../assets/img/tabler-icons/outline/cpu.svgv')
require('../assets/img/tabler-icons/outline/ad.svgv')
require('../assets/img/tabler-icons/outline/device-desktop-analytics.svgv')
require('../assets/img/tabler-icons/outline/timeline.svgv')
require('../assets/img/tabler-icons/outline/alert-triangle.svgv')
require('../assets/img/tabler-icons/filled/alert-triangle.svgv')
require('../assets/img/tabler-icons/outline/arrow-narrow-right.svgv')
require('../assets/img/tabler-icons/outline/hourglass-low.svgv')
require('../assets/img/tabler-icons/outline/copy.svgv')
require('../assets/img/tabler-icons/outline/star.svgv')
require('../assets/img/tabler-icons/outline/star.svgv')
require('../assets/img/tabler-icons/filled/star.svgv')

export default {
	props: ['name', 'color', 'stroke-width', 'class', 'size', 'title', 'filled'],

	created() {
		this.loadIcon()
	},

	watch: {
		name() {
			this.loadIcon()
		},
	},

	methods: {
		loadIcon() {
			let name = 'outline/' + this.name
				if (this.filled) name = 'filled/' + this.name
				else if (this.name.endsWith('-filled')) name = 'filled/' + this.name.substring(0, this.name.length - 7)

			import('../assets/img/tabler-icons/' + name + '.svgv').then((module) => {
				this.module = module.default
				this.$forceUpdate()
			})
		},
	},

	render() {
		let size = 24
		if (this.size) size = parseInt(this.size) || size
		if (this.size === '1x') size = 16
		if (this.size === '1.0x') size = 16
		if (this.size === '1.5x') size = 24
		if (this.size === '2x') size = 32
		if (this.size === '3x') size = 48
		if (this.size === '4x') size = 48
		let viewBox = '0 0 24 24'
		var com = this.module
		if (!com)
			return (
				<QuestionMark
					style={{flexShrink: 0}}
					viewBox={viewBox}
					width={size}
					v-tooltip={this.title}
					height={size}
					class={this.class}
					color='#00000015'
					stroke-width={this.strokeWidth || 2}
				/>
			)
		return (
			<com
				class={this.class}
				v-tooltip={this.title}
				style={{flexShrink: 0}}
				tabindex='-1'
				vOn:click={(e) => this.$emit('click', e)}
				viewBox={viewBox}
				width={size}
				height={size}
				color={this.color}
				stroke-width={this.strokeWidth || 2}
			/>
		)
	},
}
