import store from '@sb/store'
import sb from '@sb/util'

export default {
	name: 'expired-token-modal',
	data() {
		return {
			open: false,
		}
	},

	mounted() {
		this.detectTokenExpired()
		this.interval = setInterval(this.detectTokenExpired, 30_000)
	},

	beforeDestroy() {
		if (this.interval) clearInterval(this.interval)
	},

	methods: {
		detectTokenExpired() {
			console.log('detectTokenExpired', store.getTokenExpiredState())
			this.open = store.getTokenExpiredState()
			// try to blur content behind modal. This logic should put somewhere else
			if (this.open) {
				let $main = document.querySelector('.main')
				let $rootbody = document.querySelector('.root__body')
				if ($main) $main.style.filter = 'blur(5px)'
				if ($rootbody) $rootbody.style.filter = 'blur(5px)'
			}
		},

		onClickLogout() {
			store.logout()
			window.location.href = '/login'
		},
	},

	render() {
		let open = this.open

		return (
			<Modal show={open}>
				<div class='modal__background' style='overflow: hidden;'>
					<div class='modal_content' style='width: 400px;'>
						<div class='modal_content__main'>
							<div class='text__semibold text__lg'>{this.$t('login_session_expired')}</div>
							<div class='text__muted'>{this.$t('login_session_expired_desc')}</div>
							<div class='text__center mt-4'>
								<button type='button' vOn:click={this.onClickLogout} class='btn btn__primary w_100'>
									{this.$t('relogin')}
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		)
	},
}
