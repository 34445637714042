import twemoji from 'twemoji'
import sb from '@sb/util'

export default {
	name: 'emoji',
	props: ['emojiCode', 'md', 'sm', 'xs', 'lg', 'xl'],

	methods: {
		renderEmojiUnicode() {
			let cls = 'emoji'
			if (this.md) cls += ' emoji__md'
			if (this.sm) cls += ' emoji__sm'
			if (this.xs) cls += ' emoji__xs'
			if (this.lg) cls += ' emoji__lg'
			if (this.xl) cls += ' emoji__xl'
			let imgString = twemoji.parse(this.emojiCode, {
				base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/',
				className: cls,
				folder: 'svg',
				ext: '.svg',
			})
			return <div domPropsInnerHTML={imgString} class='emoji_wrapper' />
		},
	},

	render() {
		var emojiCode = this.emojiCode
		if (!emojiCode) return null
		// unicode emoji
		if (!sb.CUSTOM_EMOJI_CODES.includes(emojiCode) && !sb.CUSTOM_EMOJI_CODES.includes(`:${emojiCode}:`)) {
			return this.renderEmojiUnicode()
		}
		var emojiText = emojiCode.replace(/:/g, '')
		let cls = 'emoji emoji__' + emojiText
		if (this.md) cls += ' emoji__md'
		if (this.sm) cls += ' emoji__sm'
		if (this.xs) cls += ' emoji__xs'
		// use i tag instead of img, because img without src has a border (can't be styled)
		return <i data-tooltip={emojiCode} class={cls}></i>
	},
}
