import {STANDARD_UNLIMITED_VND_PRICE} from './trial-subscription/common'

const millisecondsInADay = 24 * 3600 * 1000
const plans_time_expired = {
	trial: {
		time_expired_limit: 7 * millisecondsInADay,
		skip_time_limit: millisecondsInADay,
	},
	standard: {
		time_expired_limit: 14 * millisecondsInADay,
		skip_time_limit: 2 * millisecondsInADay,
	},
}

export default {
	name: 'NotiBar',
	data() {
		return {
			forceCloseHelloBar: '',
			helloBarContent: '',
			helloBarMode: 'warning',

			showExpiredBar: false,
			day_expired: 0,
		}
	},
	mounted() {
		this.$root.$on('hello_bar_updated', async () => {
			let {body, error} = await store.get_hellobar(true)
			if (!error) {
				this.helloBarContent = body.content
				this.helloBarMode = body.mode || 'warning'
			}
		})

		store.onAccount(this, (payload) => {
			if (lo.get(payload, 'object_type') === 'integration' || lo.get(payload, 'object_type') === 'site') {
				this.$forceUpdate()
			}
		})

		setTimeout(async () => {
			let {body, error} = await store.get_hellobar()
			if (!error) {
				this.helloBarContent = body.content
				this.helloBarMode = body.mode || 'warning'
			}
			for (;;) {
				await sb.sleep(90000)
				let {body, error} = await store.get_hellobar()
				if (!error) {
					this.helloBarContent = body.content
					this.helloBarMode = body.mode || 'warning'
				}
			}
		})
		this.shouldShowExpiredBar()
	},

	computed: {
		shouldShowHelloBar() {
			return this.helloBarContent && this.forceCloseHelloBar !== this.helloBarContent
		},
	},
	methods: {
		skipExpiredWarning() {
			const skip_time = Date.now()
			store.setSkipTimeExpiredWarning(skip_time)
			this.showExpiredBar = false
		},

		shouldShowExpiredBar() {
			const subs = store.matchSubscription() || {}
			let plan = lo.get(subs, 'plan')

			if (plan === 'trial' || (plan === 'standard' && lo.get(subs, 'num_agents') <= 1)) {
				const end_trial_time = lo.get(subs, 'ended', 0)
				let remaining_time = sb.getMs(end_trial_time) - Date.now()
				const skip_time = store.getSkipTimeExpiredWarning()
				let plan_exp = lo.get(plans_time_expired, plan)

				if (
					plan_exp &&
					remaining_time > 0 &&
					remaining_time < plan_exp.time_expired_limit &&
					Date.now() - skip_time > plan_exp.skip_time_limit
				) {
					this.showExpiredBar = true
					this.day_expired = Math.ceil(remaining_time / millisecondsInADay)
				}
			}
			return
		},

		renderTrialExpiredBar() {
			let me = store.me()
			let scopes = lo.get(me, 'scopes', [])
			let $text_warning = (
				<div
					class='trial_expired_title text__truncate'
					domPropsInnerHTML={this.$t('trial_expired_agent_desc', [this.day_expired])}
				></div>
			)
			let $button = null

			if (me.is_owner || scopes.includes('account_manage') || scopes.includes('owner')) {
				$text_warning = (
					<div
						class='trial_expired_title text__truncate'
						domPropsInnerHTML={this.$t('trial_expired_desc', [this.day_expired])}
					></div>
				)

				$button = (
					<button
						class='btn btn__sm btn__dark'
						style='font-size: 14px; padding: 0 8px;'
						vOn:click={() => {
							this.$router.push({name: 'trial.subscriptions'})
						}}
					>
						{this.$t('compare_package')}
					</button>
				)
			}
			return (
				<div class='expired_bar'>
					<div class='flex__1 d-flex align-items-center justify-content-center' style='overflow: hidden;'>
						{$text_warning}
						{$button}
					</div>
					<div
						class='ml-3 link text__semibold text__underline'
						style='color: #000000B2; font-size: 14px;'
						vOn:click={() => this.skipExpiredWarning()}
					>
						{this.$t('skip')}
					</div>
				</div>
			)
		},

		renderStandardExpiredBar() {
			let plan = this.$t('plan_standard')
			let price = sb.displayMoney(STANDARD_UNLIMITED_VND_PRICE)
			return (
				<div class='expired_bar'>
					<div class='flex__1 d-flex align-items-center justify-content-center' style='overflow: hidden;'>
						<div
							class='trial_expired_title text__truncate'
							domPropsInnerHTML={this.$t('expired_bar_manager_content', [plan, this.day_expired, price])}
						></div>
						<div
							class='link link__primary text__semibold'
							style='font-size: 14px; padding: 0 8px;'
							vOn:click={() => {
								this.$router.push({name: 'trial.subscriptions'})
							}}
						>
							{this.$t('view_detail')}
						</div>
					</div>
					<div
						class='ml-3 link text__semibold text__underline'
						style='color: #000000B2; font-size: 14px;'
						vOn:click={() => this.skipExpiredWarning()}
					>
						{this.$t('skip')}
					</div>
				</div>
			)
		},

		displayExpiredBar() {
			let plan = lo.get(store.matchSubscription(), 'plan')

			if (plan === 'trial') return this.renderTrialExpiredBar()
			if (plan === 'standard') return this.renderStandardExpiredBar()
			return null
		},
	},

	render() {
		if (store.shouldShowOnboarding()) {
			return (
				<div
					class='hello-bar text__center text__white'
					style='height: unset; padding: 12px 0; font-size: 14px; background: #f38910'
				>
					{this.$t('integrate_to_start_chatting_long_text')}.{' '}
					<router-link to={{name: 'site-install'}} class='text__underline'>
						{this.$t('integrate_now')}
					</router-link>
				</div>
			)
		}

		if (this.shouldShowHelloBar)
			return (
				<div class={'hello-bar text-center bg-' + this.helloBarMode}>
					<span domPropsInnerHTML={this.helloBarContent}></span>
					<Icon
						name='x'
						class='clickable'
						size='16'
						style='position: absolute; top: 4px; right: 10px;'
						vOn:click={() => (this.forceCloseHelloBar = this.helloBarContent)}
					/>
				</div>
			)

		if (this.showExpiredBar) return this.displayExpiredBar()
		return null
	},
}
