
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: ["icon icon-tabler icons-tabler-filled icon-tabler-alert-triangle",classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","fill":"currentColor"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M12 1.67c.955 0 1.845.467 2.39 1.247l.105.16 8.114 13.548a2.914 2.914 0 01-2.307 4.363l-.195.008H3.882a2.914 2.914 0 01-2.582-4.2l.099-.185 8.11-13.538A2.914 2.914 0 0112 1.67zM12.01 15l-.127.007a1 1 0 000 1.986L12 17l.127-.007a1 1 0 000-1.986L12.01 15zM12 8a1 1 0 00-.993.883L11 9v4l.007.117a1 1 0 001.986 0L13 13V9l-.007-.117A1 1 0 0012 8z"}})])
          )
        }
      }
    