
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: ["icon icon-tabler icons-tabler-filled icon-tabler-thumb-up",classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","fill":"currentColor"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M13 3a3 3 0 012.995 2.824L16 6v4h2a3 3 0 012.98 2.65l.015.174L21 13l-.02.196-1.006 5.032c-.381 1.626-1.502 2.796-2.81 2.78L17 21H9a1 1 0 01-.993-.883L8 20l.001-9.536a1 1 0 01.5-.865 2.998 2.998 0 001.492-2.397L10 7V6a3 3 0 013-3zM5 10a1 1 0 01.993.883L6 11v9a1 1 0 01-.883.993L5 21H4a2 2 0 01-1.995-1.85L2 19v-7a2 2 0 011.85-1.995L4 10h1z"}})])
          )
        }
      }
    