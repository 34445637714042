
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: ["icon icon-tabler icons-tabler-outline icon-tabler-tag",classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M0 0h24v24H0z","stroke":"none"}}),_c('path',{attrs:{"d":"M6.5 7.5a1 1 0 102 0 1 1 0 10-2 0"}}),_c('path',{attrs:{"d":"M3 6v5.172a2 2 0 00.586 1.414l7.71 7.71a2.41 2.41 0 003.408 0l5.592-5.592a2.41 2.41 0 000-3.408l-7.71-7.71A2 2 0 0011.172 3H6a3 3 0 00-3 3z"}})])
          )
        }
      }
    