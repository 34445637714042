export default {
	props: ['selector'], // specific dom selector to append

	mounted() {
		let selector = this.selector || 'body'
		document.querySelector(selector).appendChild(this.$el)
	},

	beforeDestroy() {
		let selector = this.selector || 'body'
		// use try because somehow dropdown DOM of dropdown.js component was removed after this hook fire
		try {
			document.querySelector(selector).removeChild(this.$el)
		} catch (err) {
			console.log('beforeDestroy append_to_body', err)
		}
	},

	render() {
		return this.$slots.default
	},
}
